import React, { Component } from "react";
import Validator from "validatorjs";

import Alert from "../components/Alert";
import Button from "../components/Button";
import TextArea from "../components/TextArea";
import TextField from "../components/TextField";

import { request } from "../utils/request";
import { AppContext } from "../utils/context";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();
let recaptchaKey;

class Contact extends Component {
  constructor(props, context) {
    super(props);
    recaptchaKey = context.config.google_recaptcha_public_key;
    this.state = {
      validation: new Validator({}, {}),
      contact: {
        name: "",
        email: "",
        subject: "",
        message: "",
        recaptcha: ""
      },
      xhr: {
        message: ""
      },
      disabled: context.config.option_recaptcha_type === 'v2', // if type is v2, submit is disabled until recaptcha complete
      recaptchaType: context.config.option_recaptcha_type
    };
  }
  componentDidMount = async () => {};
  handleChange = e => {
    let { name, value } = e.target;
    let contact = { ...this.state.contact };
    contact[name] = value;
    this.setState({ contact });
  };
  handleSubmit = () => {
    const { contact, recaptchaType } = this.state;
    let rules = {
      name: "required",
      email: "required|email",
      subject: "required",
      message: "required"
    };
    let validation = new Validator(contact, rules);
    if (validation.passes()) {
      this.setState({ validation: validation, disabled: true });
      if (recaptchaType === 'v2') {
        this.submit();
      } else {
        recaptchaRef.current.executeAsync().then(value => {
          this.submit();
        });
      }
    } else {
      this.setState({ validation });
    }
  };
  submit = () => {
    const { contact, recaptchaType } = this.state;
    const { localLanguage } = this.context;
    //store recaptcha value in what is sent to server
    contact.recaptcha = recaptchaRef.current.getValue();
    contact.locale = localLanguage.setLocale.substring(0,2);

    return request.post("api:/web/contact", contact).then(resp => {
      let newState = {
        xhr: resp,
        disabled: recaptchaType === 'v2'
      };
      recaptchaRef.current.reset();

      if (!resp.errors && !resp.exception) {
        newState.contact = {
          name: "",
          email: "",
          subject: "",
          message: ""
        };
      }
      this.setState(newState);
      return resp;
    });
  };
  onChange = (value) => {
    if (value) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  render() {
    const { localLanguage, settings } = this.context;
    const { contact, validation, xhr, disabled, recaptchaType } = this.state;
    const { errors } = validation.errors;
    return (
      <div className="container mx-auto py-6 contactPage">
        <h1 className="mb-6">{localLanguage.general_contact_us}</h1>
        <div className="content">
          <div className="flex flex-wrap mb-6">
              <div className={`content`}
                  dangerouslySetInnerHTML={{
                    __html: localLanguage.general_custom_contact_us_text
                  }}
                />
            </div>
          {xhr.message !== "" && (
            <div className="flex flex-wrap mb-6">
              <div className="w-full md:w-1/2">
                <Alert
                  error={xhr.errors || xhr.exception}
                  success={xhr.success}
                  message={xhr.message}
                />
              </div>
            </div>
          )}
          <div className="flex flex-wrap mb-6">
            <TextField
              container="w-full md:w-1/2"
              id="name"
              name="name"
              value={contact.name}
              label={localLanguage.general_first_name}
              placeholder={localLanguage.general_first_name}
              required
              handleChange={this.handleChange}
              hasError={errors["name"]}
              displayError={localLanguage.general_field_is_required}
              language={localLanguage.setLocale}
            />
          </div>
          <div className="flex flex-wrap mb-6">
            <TextField
              container="w-full md:w-1/2"
              id="email"
              name="email"
              value={contact.email}
              label={localLanguage.general_email}
              placeholder={localLanguage.general_email}
              required
              handleChange={this.handleChange}
              hasError={errors["email"]}
              displayError={localLanguage.general_field_is_required}
              language={localLanguage.setLocale}
            />
          </div>
          <div className="flex flex-wrap mb-6">
            <TextField
              container="w-full md:w-1/2"
              id="subject"
              name="subject"
              value={contact.subject}
              label={localLanguage.general_subject}
              placeholder={localLanguage.general_subject}
              required
              handleChange={this.handleChange}
              hasError={errors["subject"]}
              displayError={localLanguage.general_field_is_required}
              language={localLanguage.setLocale}
            />
          </div>
          <div className="flex flex-wrap mb-6">
            <TextArea
              container="w-full md:w-1/2"
              id="message"
              name="message"
              value={contact.message}
              label={localLanguage.general_message}
              placeholder={localLanguage.general_whats_on_mind}
              required
              handleChange={this.handleChange}
              hasError={errors["message"]}
              displayError={localLanguage.general_field_is_required}
              language={localLanguage.setLocale}
            />
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={recaptchaType === 'v2' ? this.onChange : null}
            size={recaptchaType === 'v2' ? 'small' : 'invisible'}
          />

          {!disabled &&
            <div className="flex flex-wrap mb-6">
              <Button color="primary" onClick={this.handleSubmit} />
            </div>
          }
        </div>
      </div>
    );
  }
}
Contact.contextType = AppContext;

export default Contact;
