import React, { Component } from "react";
import { AppContext } from "../utils/context";
import RemainingCards from "./RemainingCards";
import RemainingEnvelopes from "./RemainingEnvelopes";

class RemainingCardsEnvelopes extends Component {
  state = {
    isEmptyState: null
  }
  triggerModalState = (e) => {
    this.setState({
      isEmptyState: e.target.getAttribute('data-id')
    });
  };
  closeModal = () => {
    this.setState({
      isEmptyState: null
    });
  };
  getEnvelopeHeight = (url) => {
    let image = new Image();
    image.src = url;
    let height = image.height > 640 ? 640 : image.height;
    return height;
  }
  render() {
    const { event, isWinnersPage } = this.props;
    const { settings, localLanguage } = this.context;

    let envelopeBackgroundImageHeight = this.getEnvelopeHeight(settings.envelope_background_image);

    const containerClassNames = isWinnersPage? "flex justify-end rounded text-base" : "flex justify-center bg-primary rounded text-base homePageRemainingCards";

    return (
      <div>
        <div className={containerClassNames}>
          <button
            className="flex items-center leading-normal bg-secondary border-secondary rounded px-4 py-2 whitespace-no-wrap text-white font-semibold hover:bg-secondary-dark"
            onClick={this.triggerModalState}
            data-id={event.id}
          >
            {
              settings.web_show_remaining === "cards" ? (
                localLanguage.general_see_cards_remaining
              ) : (
                  localLanguage.general_see_env_remaining
                )
            }</button>
        </div>
        {this.state.isEmptyState && (
          <div>
            {
              settings.web_show_remaining === "cards" ? (
                <RemainingCards
                  closeModal={this.closeModal}
                  eventId={event.id}
                />
              ) : (
                  <RemainingEnvelopes
                    closeModal={this.closeModal}
                    eventId={event.id}
                    show_weekly_jackpot={settings.show_weekly_jackpot}
                    envelope_number_color={settings.envelope_number_color}
                    envelope_background_image={settings.envelope_background_image}
                    envelopeBackgroundImageHeight={envelopeBackgroundImageHeight < +settings.envelope_background_default_height ? +settings.envelope_background_default_height : envelopeBackgroundImageHeight}
                  />
                )
            }
          </div>
        )}
      </div>
    );
  }
}
RemainingCardsEnvelopes.contextType = AppContext;

export default RemainingCardsEnvelopes;
