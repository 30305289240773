import React, { Component } from "react";

class Dropdown extends Component {
  render() {
    const {
      container,
      label,
      id,
      placeholder,
      name,
      value,
      optionsCount,
      required,
      handleChange,
      hasError,
      disabled
    } = this.props;
    const options = [];
    for (let i = 0; i <= optionsCount; i++) {
      options.push(i);
    }
    let labelClass = "w-full block font-bold mb-2 px-6";
    if (required) labelClass += " input-required";
    return (
      <div className={container + " inline-flex"}>
        <label className={labelClass} htmlFor={id}>
          {label} {required ? <span className="text-danger">*</span> : ""}
        </label>
        <select
          className="appearance-none border rounded px-4 mb-3 leading-tight bg-white focus:outline-none focus:bg-white"
          id={id}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          required={required}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {hasError && <p className="text-danger text-xs italic">{hasError}</p>}
      </div>
    );
  }
}

export default Dropdown;
