import React, { Component } from "react";
import { Link } from "react-router-dom";

import Button from "./Button";
import { AppContext } from "../utils/context";

class CheckoutError extends Component {
  render() {
    const { error, order, event, errorReturnHome} = this.props;
    const { localLanguage } = this.context;

    let hrefLink = '#';
    return (
      <form key={4} className="w-full md:w-2/3 checkoutError">
        <h2 className="mb-6">{localLanguage.general_were_sorry}</h2>
        {(error.code > 0 || error.message !== "") && (
          <div className="errorMessage">

            {error.code !== 2 && (
              <p className="leading-normal mb-2">
                {error.message}
                <br />
                <br />
                <Button color="accent" textColor="primary" onClick={() => errorReturnHome()} content={localLanguage.general_return_home}/>
              </p>
            )}

            {error.code === 2 && (
              <h2 className="mb-6">{localLanguage.locationError_title}</h2>
            )}
            {error.code === 2 && (
              <p className="leading-normal mb-2 p1">
                {localLanguage.locationError_message}
              </p>
            )}
            {error.code === 2 && (
              <p className="leading-normal mb-2 p2">
                {error.message}
              </p>
            )}
            {error.code === 2 && (
              <p className="leading-normal">
                <Link to={localLanguage.locationError_link}>{localLanguage.general_click_learn_location}</Link><br />
                <br />
              </p>
            )}

            {error.action === "default" && (
              <p className="leading-normal">
                <Link to="/">{localLanguage.general_return_home}</Link>
              </p>
            )}

            {error.action === "reload" && (
              <p className="leading-normal">
                <a
                  href={hrefLink}
                  onClick={e => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  {localLanguage.general_reload_page}
                </a>
              </p>
            )}
          </div>
        )}
      </form>
    );
  }
}
CheckoutError.contextType = AppContext;

export default CheckoutError;
