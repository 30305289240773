import React, { Component } from "react";

import { AppContext } from "../utils/context";

class Alert extends Component {
  render() {
    const { localLanguage } = this.context;
    const { error, warning, success, message, dismissable } = this.props;

    let title = localLanguage.general_alert;
    let color = "info";
    if (error) {
      title = localLanguage.general_error;
      color = "danger";
    }
    if (warning) {
      title = localLanguage.general_warning;
      color = "warning";
    }
    if (success) {
      title = localLanguage.general_success;
      color = "success";
    }
    return (
      <div
        className={`w-full bg-${color}-lightest border border-${color}-light text-${color}-dark px-4 py-3 rounded relative`}
        role="alert"
      >
        <strong className="font-bold mr-2">{title}!</strong>
        <span className="block sm:inline">{message ? message : ""}</span>
        {dismissable && (
          <span className="absolute pin-t pin-b pin-r px-4 py-3">
            <svg
              className="fill-current h-6 w-6 text-red"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>{localLanguage.general_close}</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        )}
      </div>
    );
  }
}
Alert.contextType = AppContext;

export default Alert;
