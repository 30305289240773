import React, { Component } from "react";
import logo from "../assets/images/logo.png";
import FooterPoweredBy from "./FooterPoweredBy";
import SocialFollow from "./SocialFollow";
import FooterText from "./FooterText";

class FooterBody extends Component {

    render() {

        const { socialFollowFacebookUrl, socialFollowTwitterkUrl, socialFollowYoutubeUrl, socialInstagramUrl } = this.props;
        return (
            <div>
                <FooterText />
                <SocialFollow
                    socialFollowFacebookUrl={socialFollowFacebookUrl}
                    socialFollowTwitterkUrl={socialFollowTwitterkUrl}
                    socialFollowYoutubeUrl={socialFollowYoutubeUrl}
                    socialInstagramUrl={socialInstagramUrl}
                />
                {/* <FooterPoweredBy/>        */}
            </div>

        );
    }
}

export default FooterBody;
