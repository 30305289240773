import React, { Component } from "react";
import parse from 'html-react-parser'

class CheckboxField extends Component {
  render() {
    const {
      container,
      label,
      options,
      name,
      required,
      inline,
      hasError,
      handleChange,
      displayError,
      language
    } = this.props;
    let labelClass = "block font-bold mb-2";
    if (required) labelClass += " input-required";
    let inlineClass = "py-3 pr-4";
    inlineClass += (!inline) ? " w-full" : " flex";
    return (
      <div className={container}>
        {label !== false && (<p className={labelClass}>{label}</p>)}
        <div className="flex flex-row flex-wrap">
          {options.map((v, k) => v.text !== "" && (
            <div key={k} className={inlineClass}>
              <label className="block">
                <input
                  className="mr-2"
                  type="checkbox"
                  name={`${name}`}
                  value={v.value}
                  checked={v.checked === true ? "checked" : false}
                  onChange={handleChange}
                />
                {parse(v.text)}  {required ? <span className="text-danger">*</span> : ""}
              </label>
              
              {hasError && hasError[k] && language.includes('en') && <p className="block text-danger text-xs italic my-2">{hasError[k]}</p>}
              {hasError && hasError[k] && !language.includes('en') && <p className="block text-danger text-xs italic my-2">{displayError}</p>}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CheckboxField;
