import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../utils/context";
import { formatCurrency } from "../utils/constants";

class MainHero extends Component {
  render() {
    const { event, localLanguage, settings, config } = this.context;
    const pricing = event.prices;
    let linkClassNames = "inline-block no-underline bg-accent hover:bg-accent-dark text-primary font-bold py-3 px-6 rounded my-4 shadow-outline";

    return (
      <div className="main-tickets bg-primary text-accent py-4 ">
        <div className="container mx-auto" style={{maxWidth: "100%"}}>
          <div className="flex items-stretch flex-wrap">
            {pricing && pricing.map((pricePoint, k) => (
              <div
                key={k}
                className={"w-full md:flex-1".concat(pricePoint['disabled'] ? " notAllowedPricepoint" : "")}>
                <div
                  className={
                    k === (pricing.length-1)
                      ? "block text-center"
                      : "block text-center border-r"
                  }
                >
                  <div
                    className="font-normal text-xl webPricePointLabel highlightPricePointColorClass"
                    style={pricePoint['highlight'] > 0 ? {color: settings.highlight_pricing_color} : {}}
                  >
                    {pricePoint['quantity']}&nbsp;
                    {config.option_number_model === 'single_number_multi_chance' ? (pricePoint['quantity'] > 1 ? localLanguage.general_chances_to_win : localLanguage.general_chance_to_win)  : localLanguage.general_ticket + (pricePoint['quantity'] > 1 ? "s" : "") }&nbsp;
                    {localLanguage.general_for}
                  </div>
                  <div className="font-semibold text-2xl my-2 webPricePointValue highlightPricePointColorClass" style={pricePoint['highlight'] > 0 ? {color: settings.highlight_pricing_color} : {}}>
                    {formatCurrency(pricePoint['price'])}
                  </div>
                  {event.is_active && event.web_enabled && event.websales_active && +event.web_sales_paused !== 1 && +event.shopify_enabled !== 1 && (
                    <Link
                      to={{
                        pathname: `/select-tickets`,
                        state: {
                          pricePointKey: k
                        }
                      }}
                      className={linkClassNames.concat(pricePoint['disabled'] ? " notAllowedBuyNowButton" : "")}
                      style={settings.font_family_hero !== "" ? { fontFamily: settings.font_family_hero} : {fontFamily: settings.font_family_hero}}
                    >
                      {localLanguage.buy_now_text}
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
MainHero.contextType = AppContext;

export default MainHero;
