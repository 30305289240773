import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { AppContext } from '../utils/context';

class FooterLinks extends Component {
    render() {
        const { settings, localLanguage } = this.context;
        var d = new Date();
        return (
            <div className=" border-accent-lightest border-t-2" >
                <nav className="flex items-center justify-between flex-wrap p-2 lg:flex-row-reverse">

                    <div className="w-full  flex-grow lg:flex lg:items-center lg:w-auto">
                        <div className="text-sm text-center lg:text-left lg:flex-grow">
                            <Link
                                to="/winners"
                                className="mt-4 lg:mt-0 text-accent hover:text-accent-dark mr-4 winnersFooterLink  no-underline"
                            >
                                {localLanguage.general_winners}
                <div className="vbar footer border-accent-lightest" />
                            </Link>

                            {settings && settings.webaboutustext && settings.webaboutustext !== "0" && (

                                <Link
                                    to="/about"
                                    className="mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 webaboutustextFooterLink  no-underline"
                                >
                                    {localLanguage.aboutus_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>

                            )}
                            {settings && settings.webfaqtext && settings.webfaqtext !== "0" && (
                                <Link
                                    to="/faq"
                                    className="mt-4 lg:mt-0 text-accent hover:text-accent-dark mr-4 webfaqtextFooterLink  no-underline"
                                >
                                    {localLanguage.faq_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            {settings && settings.webprivacytext && settings.webprivacytext !== "0" && (
                                <Link
                                    to="/privacy"
                                    className=" mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 webprivacytextFooterLink  no-underline"
                                >
                                    {localLanguage.privacy_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            {settings && settings.webrulestext && settings.webrulestext !== "0" && (
                                <Link
                                    to="/rules"
                                    className=" mt-4 lg:mt-0 text-accent hover:text-accent-dark mr-4 webrulestextFooterLink  no-underline"
                                >
                                    {localLanguage.rules_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            {settings && settings.webtermstext && settings.webtermstext !== "0" && (
                                <Link
                                    to="/terms"
                                    className=" mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 webtermstextFooterLink  no-underline"
                                >
                                    {localLanguage.terms_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            {settings && settings.webclaimformstext && settings.webclaimformstext !== "0" && (
                                <Link
                                    to="/claim-forms"
                                    className=" mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 webclaimformstextFooterLink  no-underline"
                                >
                                    {localLanguage.claimforms_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            {settings && settings.webproceedstext && settings.webproceedstext !== "0" && (
                                <Link
                                    to="/proceeds"
                                    className=" mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 webproceedstextFooterLink  no-underline "
                                >
                                    {localLanguage.proceeds_link_name}
                                    <div className="vbar footer border-accent-lightest" />
                                </Link>
                            )}
                            <Link
                                to="/contact"
                                className=" mt-4  lg:mt-0 text-accent hover:text-accent-dark mr-4 contactFooterLink  no-underline"
                            >
                                {localLanguage.contactus_link_name}

                            </Link>
                        </div>
                        <div className="w-full block py-2 text-center text-sm lg:text-left lg:flex lg:items-center lg:w-auto  no-underline">
                            <span>
                                &copy; {localLanguage.general_copyright_year} <span>{d.getFullYear()}</span>
                            </span>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}
FooterLinks.contextType = AppContext;

export default FooterLinks;
