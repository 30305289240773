import React, { Component } from "react";
import { request } from "../utils/request";
import { AppContext } from "../utils/context";
import { formatCurrency } from "../utils/constants"
import DisplayCard from "./DisplayCard";

class EnvelopesRemaining extends Component {
    state = {
        event: null
    }
    componentDidMount = async () => {
        this.getEvent();
        document.addEventListener("keydown", this.handleKeyDown)
    }
    componentDidUpdate = prevProps => {
        if (prevProps.eventId !== this.props.eventId) {
            this.getEvent();
        }
    }
    getEvent = async () => {
        const { eventId } = this.props;
        let event = await request.get(`api:/web/event/${eventId}`, {});
        this.setState({
            event: event
        });
    };
    handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            this.props.closeModal();
        }
    }
    render() {
        const { event } = this.state;
        const { show_weekly_jackpot, envelope_number_color, envelope_background_image, envelopeBackgroundImageHeight } = this.props;
        const { localLanguage } = this.context;
        if (event === null) return <div />
        return (
            <div className="fixed pin z-50 overflow-auto flex" onKeyDown={this.handleKeyDown} tabIndex="0" id="remainingCardEnvelope">
                <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex shadow-md">
                    <div className="w-full rounded flex flex-col justify-center items-center text-center p-6 bg-white">
                        <div className="text-accent text-2xl font-bold flex flex-col">
                            <span className="uppercase">{event !== null ? event.title : ''}</span>{" "}
                        </div>

                            <div className="w-full flex justify-around px-6 py-4">
                                <div>
                                    <p className="text-secondary text-center font-light text-base">
                                        {localLanguage.game_3_jackpot_title.toUpperCase()}
                                    </p>
                                    <p className="text-accent text-center font-bold text-4xl">
                                        {formatCurrency(event.jackpot)}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-secondary text-center font-light text-base">
                                        {localLanguage.game_3_prize_title.toUpperCase()}
                                    </p>
                                    {+show_weekly_jackpot === 1 &&
                                        <p className="text-accent text-center font-bold text-4xl">
                                            {formatCurrency(event.prize)}
                                        </p>
                                    }
                                </div>
                            </div>

                        <div className="flex flex-wrap justify-center mb-6 customFlexEnvelope">
                            {event !== null && event.cards.map((value, key) => (
                                <DisplayCard
                                    envelopeNumberColor={envelope_number_color}
                                    envelopeBackgroundImage={envelope_background_image}
                                    envelopeBackgroundImageHeight={envelopeBackgroundImageHeight}
                                    key={key}
                                    cardInfo={value}
                                    eventId={event.id}
                                    flag={"remainingEnvelopes"}
                                />
                            ))}
                        </div>
                    </div>
                    <span className="absolute pin-t pin-b pin-r p-4">
                        <svg
                            className="h-12 w-12 fill-current text-grey hover:text-grey-darkest"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            onClick={this.props.closeModal}
                        >
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            </div>
        );
    }
}
EnvelopesRemaining.contextType = AppContext;

export default EnvelopesRemaining;
