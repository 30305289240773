import React, { Component } from "react";

import { formatCurrency } from "../utils/constants"
import { AppContext } from "../utils/context";

class EventSelection extends Component {
  setEventIteratorCookie = (objectId) => {
    const { cookies } = this.props;
    cookies.set('eventIterator', objectId);
  }
  render() {
    const { events, cookies } = this.props;
    const { localLanguage } = this.context;
    const prevPath = cookies.get('previousPath');

    if (events.length === 0) {
      return (
        <div className="bg-white text-center py-4 lg:px-4 h-64">
          <p className="font-bold">{localLanguage.general_events_coming_soon}</p>
        </div>
      )
    }
    return (
      <div>
        <p className="text-center font-bold my-4">{localLanguage.general_select_event_below}</p>
        {events.map((v, k) => (
          <div key={`eventSelection${k}`}>
            <a
            href={`${prevPath}?eventId=${v.id}`}
            onClick={() => this.setEventIteratorCookie(k)}
            className="block max-w-sm mx-auto my-4 overflow-hidden rounded-lg no-underline bg-primary-darkest hover:bg-primary-darker"
            >
              <div className="flex items-start px-6 py-4">
                <div className="flex-grow font-bold text-white text-xl">{v.title}</div>
                <div className="bg-grey-lighter rounded-full px-3 py-1 text-xs font-semibold text-grey-darker">{v.game_title}</div>
              </div>

              {/* Split the Pot Raffle */}

              {v.game_id === 1 && (
                <div className="flex justify-around px-6 py-4">
                  <div>
                    <p className="text-white text-center font-light text-base">
                      {localLanguage.general_current_jackpot}
                      </p>
                    <p className="text-accent text-center font-bold text-4xl">
                      {formatCurrency(v.jackpot)}
                    </p>
                  </div>
                  <div>
                    <p className="text-white text-center font-light text-base">
                      {localLanguage.general_current_prize}
                      </p>
                    <p className="text-accent text-center font-bold text-4xl">
                      {formatCurrency(v.prize)}
                    </p>
                  </div>
                </div>
              )}

              {/* Merchandise Raffle */}

              {v.game_id === 2 && (
                <div className="flex justify-around px-6 py-4">
                <div>
                  <p className="text-white text-center font-light text-base">
                    {localLanguage.general_prize}
                    </p>
                  <p className="text-accent text-center font-bold text-4xl">
                    {v.draws && v.draws[0] && v.draws[0]['title']}
                  </p>
                </div>
              </div>
              )}

              {/* CTA Raffle */}

              {v.game_id === 3 && (
                <div className="flex justify-around px-6 py-4">
                  <div>
                    <p className="text-white text-center font-light text-base">
                      {localLanguage.game_3_jackpot_title.toUpperCase()}
                    </p>
                    <p className="text-accent text-center font-bold text-4xl">
                      {formatCurrency(v.jackpot)}
                    </p>
                  </div>
                  <div>
                    <p className="text-white text-center font-light text-base">
                      {localLanguage.game_3_prize_title.toUpperCase()}
                    </p>
                    <p className="text-accent text-center font-bold text-4xl">
                      {formatCurrency(v.prize)}
                    </p>
                  </div>
                </div>
              )}

              {/* Calendar Raffle */}

              {v.game_id === 4 && (
                <div className="flex justify-around px-6 py-4">
                    <div>
                    <p className="text-white text-center font-light text-base">
                        {localLanguage.general_calendar_prize}
                        </p>
                    <p className="text-accent text-center font-bold text-4xl">
                        {formatCurrency(v.jackpot)}
                    </p>
                    </div>
                </div>
              )}

            </a>
          </div>
        ))}
      </div>
    );
  }
}
EventSelection.contextType = AppContext;

export default EventSelection;
