import React, { Component } from "react";

import { AppContext } from "../utils/context";

class PrizeOnlyDrawHowItWorks extends Component {
    render() {
        const { localLanguage } = this.context;
        const { step1_logo,
            step2_logo,
            step3_logo,
            step4_logo,
        } = this.props;
        return (
            <div className="container mx-auto">
                <h1 className="font-bold text-center my-6">
                {localLanguage.game_2_steps_main_title}
                </h1>

                <div className="flex items-stretch flex-wrap">
                    <div className="w-full md:w-1/4 text-center">
                        <img src={step1_logo}
                            alt={localLanguage.general_logo}
                            className="block mx-auto h-32 step1LogoClass"
                        />

                        {step1_logo === "" && <svg
                            className="fill-current text-accent w-24 h-24 block mx-auto my-8 step1SvgClass"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M20 12v5H0v-5a2 2 0 1 0 0-4V3h20v5a2 2 0 1 0 0 4zM3 5v10h14V5H3zm7 7.08l-2.92 2.04L8.1 10.7 5.27 8.56l3.56-.08L10 5.12l1.17 3.36 3.56.08-2.84 2.15 1.03 3.4L10 12.09z" />
                        </svg>}

                        <span className="font-bold text-2xl text-accent mr-1">1.</span>
                        <span className="text-xl">{localLanguage.game_2_step_1_title}</span>
                        <div className="text-base my-2">
                            {localLanguage.game_2_step_1_desc}
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 text-center">
                        <img src={step2_logo}
                            alt={localLanguage.general_logo}
                            className="block mx-auto h-32 step2LogoClass"
                        />

                        {step2_logo === "" && <svg
                            className="fill-current text-accent w-24 h-24 block mx-auto my-8 step2SvgClass"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                        </svg>}

                        <span className="font-bold text-2xl text-accent mr-1">2.</span>
                        <span className="text-xl">{localLanguage.game_2_step_2_title}</span>
                        <div className="text-base my-2">
                            {localLanguage.game_2_step_2_desc}
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 text-center">
                        <img src={step3_logo}
                            alt={localLanguage.general_logo}
                            className="block mx-auto h-32 step3LogoClass"
                        />

                        {step3_logo === "" && <svg
                            className="fill-current text-accent w-24 h-24 block mx-auto my-8 step3SvgClass"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M15 9a3 3 0 0 0 3-3h2a5 5 0 0 1-5.1 5 5 5 0 0 1-3.9 3.9V17l5 2v1H4v-1l5-2v-2.1A5 5 0 0 1 5.1 11H5a5 5 0 0 1-5-5h2a3 3 0 0 0 3 3V4H2v2H0V2h5V0h10v2h5v4h-2V4h-3v5z" />
                        </svg>}

                        <span className="font-bold text-2xl text-accent mr-1">3.</span>
                        <span className="text-xl">{localLanguage.game_2_step_3_title}</span>
                        <div className="text-base my-2">
                            {localLanguage.game_2_step_3_desc}
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 text-center">
                        <img src={step4_logo}
                            alt={localLanguage.general_logo}
                            className="block mx-auto h-32 step4LogoClass"
                        />

                        {step4_logo === "" && <svg
                            className="fill-current text-accent w-24 h-24 block mx-auto my-8 step4SvgClass"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
                        </svg>}

                        <span className="font-bold text-2xl text-accent mr-1">4.</span>
                        <span className="text-xl">{localLanguage.game_2_step_4_title}</span>
                        <div className="text-base my-2">
                            {localLanguage.game_2_step_4_desc}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
PrizeOnlyDrawHowItWorks.contextType = AppContext;

export default PrizeOnlyDrawHowItWorks;
