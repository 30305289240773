import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from "./../utils/context";

/**
 * Note :
 * If you're using react v 15.4 or less
 * You can directly import PropTypes from react instead.
 * Refer to this : https://reactjs.org/warnings/dont-call-proptypes.html
 */

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentDidUpdate = prevProps => {
        if (prevProps.date !== this.props.date) {
            this.stop();
            this.interval = setInterval(() => {
                const date = this.calculateCountdown(this.props.date);
                date ? this.setState(date) : this.stop();
            }, 1000);
        }
    };

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) { // calculate countdown timer
      if (!endDate) return false;

      // use moment timezone library
      const moment = require('moment-timezone');

      const { timeZone } = this.context.config;

      // create a moment object for the event end time
      const endAt = moment.tz(endDate, timeZone);

      // convert to UTC
      const endAtUTC = endAt.utc();

      // current time in UTC
      const now = moment.utc(); // UTC time

      // take the difference
      let diff = endAtUTC.diff(now, "seconds");

      // Clear countdown when date is reached
      if (diff < 0) return false;

      const timeLeft = {
          years: 0,
          days: 0,
          hours: 0,
          min: 0,
          sec: 0
      };

      // Calculate time difference between now and the expected date
      if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
          timeLeft.years = Math.floor(diff / (365.25 * 86400));
          diff -= timeLeft.years * (365.25 * 86400);
      }
      if (diff >= 86400) { // 24 * 60 * 60
          timeLeft.days = Math.floor(diff / 86400);
          diff -= timeLeft.days * 86400;
      }
      if (diff >= 3600) { // 60 * 60
          timeLeft.hours = Math.floor(diff / 3600);
          diff -= timeLeft.hours * 3600;
      }
      if (diff >= 60) {
          timeLeft.min = Math.floor(diff / 60);
          diff -= timeLeft.min * 60;
      }
      timeLeft.sec = diff;

      return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {
        const countDown = this.state;
        const { countdownLabel } = this.props;
        const { localLanguage } = this.context;

        return (
            <div className="Countdown p-2 text-center text-accent bg-primary webCountDownTimer">

                <div className="m-6">
                    <span className="text-5xl ">{countdownLabel}</span>
                </div>
                {(countDown.days > 0 || countDown.hours > 0 || countDown.min > 0 || countDown.sec > 0) &&
                    <div className="m-4">
                        <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong>{this.addLeadingZeros(countDown.days)}</strong>
                                <span className="mt-4">
                                    {countDown.days === 1 ? localLanguage.general_day : localLanguage.general_days}
                                </span>
                            </span>
                        </span>

                        <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong>{this.addLeadingZeros(countDown.hours)}</strong>
                                <span className="mt-4">{localLanguage.general_hours}</span>
                            </span>
                        </span>


                        <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong>{this.addLeadingZeros(countDown.min)}</strong>
                                <span className="mt-4">{localLanguage.general_min_minutes}</span>
                            </span>
                        </span>

                        <span className="Countdown-col">
                            <span className="Countdown-col-element">
                                <strong>{this.addLeadingZeros(countDown.sec)}</strong>
                                <span className="mt-4">{localLanguage.general_sec_seconds}</span>
                            </span>
                        </span>
                    </div>
                }
            </div>
        );
    }
}

Countdown.propTypes = {
    date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
    date: new Date()
};

Countdown.contextType = AppContext;

export default Countdown;
