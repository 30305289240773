import React, { Component } from "react";

import { request } from "../utils/request";
import { formatCurrency } from "../utils/constants";
import { AppContext } from "../utils/context";

class MainLargestJackpot extends Component {
    state = {
        jackpot: 0,
        prize: 0
    };
    componentDidMount = () => {
        this.getLargestJackpot();
    };
    componentDidUpdate = prevProps => {
        if (prevProps.event !== this.props.event) {
            this.getLargestJackpot();
        }
    };
    getLargestJackpot = () => {
        const { game } = this.props.event;
        return request.get("api:/web/largest-jackpot", { game_id: game.id }).then(resp => {
            this.setState(
                {
                    jackpot: resp.jackpot,
                    prize: resp.prize
                },
                this.doneLoading
            );
        });
    }
    render() {
        const { event } = this.props;
        const { localLanguage } = this.context;
        const { jackpot } = this.state;

        return (
            <div className="main-largest-jackpot bg-primary py-4">
                    <div className="container mx-auto text-center text-4xl p-6">
                        <span className="font-light text-secondary mr-2">
                            {localLanguage.general_largest_jp_to_date.replace("{jackpot_title}", localLanguage['game_' + event.game.id + '_jackpot_title'])}
                        </span>
                        <span className="font-semibold text-secondary">
                            {formatCurrency(jackpot)}
                        </span>
                    </div>
            </div>
        );
    }
}
MainLargestJackpot.contextType = AppContext;

export default MainLargestJackpot;
