import React, { Component } from "react";
import { AppContext } from "../utils/context";

class FooterText extends Component {
  render() {
    return (
      <div className="bg-primary py-2">
        <AppContext.Consumer>
          {value => (
            <div
              className="font-light text-center text-sm"
              dangerouslySetInnerHTML={{
                __html: value.settings.license_number
              }} />
          )}
        </AppContext.Consumer>
      </div >
    );
  }
}

export default FooterText;
