import React, { Component, useEffect } from "react";
import { AppContext } from "../utils/context";
import { withRouter } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { request } from "../utils/request";

class CheckoutAgeVerification extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      showModal: false,
      backgroundColor: "",
      stripe: null,
      verificationtype: "SSN",
      showCountDown: false,
      countdownRestartTimes: 0,
      isCountdownRunning: false,
      displayFailedMessage: false,
      personalData: {},
      cartData: [],
      addressData: {},
      client_secret: "",
      email: "",
      phone:"",
      first_name:"",
      last_name:"",
    };
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.props.handleCloseModal();

  }

  componentDidMount = async  () =>  {
    const cartData = this.props.cart.map(({ ppId, qty, eventId }) => ({ ppId, qty, eventId }))
    const { address, age_range, city, country, email, first_name, last_name, id, phone, phone_home, phone_work, state, suite, title, zip_code } = this.props.personal;
    const personalData = { age_range, email, first_name, last_name, id, phone, phone_home, phone_work}
    const addressData = { address, city, country, state, suite, zip_code }

    this.setState({first_name: first_name, phone: phone, last_name: last_name, email: email, addressData: addressData, cartData: cartData, personalData: personalData, showModal: this.props.showModal, backgroundColor: this.props.backgroundColor, stripe: await loadStripe(this.props.stripePublicKey)})
    document.body.classList.add('modal-open'); // Add the 'modal-open' class when mounting the component
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open'); // Remove the 'modal-open' class when unmounting the component
  }

  handleSelectChange = (event) => {
    this.setState({verificationtype: event.target.value})

  };

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">{this.context.localLanguage.age_verification_still_processing_message}</div>;
    }
  
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  createStripeSession = async (event) => {
    const { localLanguage } = this.context;
    event.preventDefault();
    let payload = {
      'type' : this.state.verificationtype === "SSN" ? "id_number" : "document",
      'encodedCartObject': btoa(JSON.stringify(this.state.cartData)),
      'encodedPersonalObject': btoa(JSON.stringify(this.state.personalData)),
      'encodedAddressObject': btoa(JSON.stringify(this.state.addressData)),
      'processor': 'web',
      'eventId': this.props.eventId,
      'email': this.state.email,
      'first_name': this.state.first_name,
      'last_name': this.state.last_name,
      'phone': this.state.phone,
      'locale': localLanguage.setLocale.substring(0,2) || 'en'
    }

    try {
      const response = await request.post(`api:/stripe/create-verification-session`, payload);

      const stripe = this.state.stripe;

      const result = await stripe.verifyIdentity(response.client_secret);
      this.setState({client_secret: response.id, countdownRestartTimes: 0})
      const error = result.error
      if (error) {
        this.setState({isCountdownRunning: false})
        alert("We were unable to verify your age. Please try again")
        this.handleCloseModal()        
      }
      else {
        //submit successfully have to start countdown and fetch verification result from BE every 8seconds
        this.setState({showCountDown: true, isCountdownRunning: true})
      }
    } catch (error) {
        //stop the countdown
        this.setState({isCountdownRunning: false})
        //close the modal and display an alert 
        alert(this.context.localLanguage.age_verification_exception_message);
        //close the modal
        this.handleCloseModal()
    }
    //after stripe verification submitted show count down
    this.setState({showCountDown: true, isCountdownRunning: true})
  }

  handleCountdownComplete = () => {
    const newCount = this.state.countdownRestartTimes + 1;
      this.fetchVerificationResult().then((result) => {
        if (result === true) {
          this.handleVerificationSuccess();
        } else if (result === false) {
          this.setState({ showCountDown: false });
          this.setState({ displayFailedMessage: true });
        } 
      }).catch((error) => {
        this.setState({ shouldRepeat: false });
      });
      

    if (newCount === 3) {
      this.setState({countdownRestartTimes: 0, isCountdownRunning: false})
      this.fetchVerificationResult(true).then((result) => {
        return { shouldRepeat: false }
      }).catch((error) => {
        return { shouldRepeat: false }
      })
    } else {
      this.setState({countdownRestartTimes: newCount})
      return { shouldRepeat: true, delay: 1 }
    }
  }

  fetchVerificationResult = async (sendSuccessEmailLater = false) => {
      let payload = {
           'email' : this.state.email,
           'session_id' : this.state.client_secret,
           'send_success_email_later' : sendSuccessEmailLater,
           'processor': 'web',
      }
      try{
        const response = await request.post(`api:/stripe/fetch-verification-result`, payload);
         if (response.verified == 1) {
          return true
        } else if (response.verified == 0) {
          return false;
        } else {
          return "";
        }
      } catch (error) {
        return false
      }
  }

  handleVerificationSuccess = () => {
    this.setState({ showModal: false });
    this.props.handleVerificationSuccess();
  }

  handleVerificationFailure = async () => {
    this.setState({ showModal: false });
    this.props.handleCloseModal();
  }

  render() {
    const { showModal, backgroundColor, verificationtype, showCountDown, isCountdownRunning, displayFailedMessage } = this.state;
    const { localLanguage } = this.context;

    const gradientColor = "#000000";
  
    const gradientStyle = {
      background: `linear-gradient(to right, ${backgroundColor}, ${gradientColor})`
    };

    if (showModal && !showCountDown) {
      return (<div className="modal-overlay">
      <div className="modal-content" style={gradientStyle}>
        <button
          style={{ color: "white" }}
          type="button"
          className="close-button"
          onClick={this.handleCloseModal}
        >X</button>
        <div className="px-6 py-6 lg:px-8">
          <h3 className="mb-6 text-xl font-medium text-gray-900 dark:text-white">{localLanguage.age_verification_start_title}</h3>
          <form onSubmit={this.createStripeSession} className="space-y-6 mb-6" action="#">
          <label htmlFor="ageVerification" className="block mb-4 text-sm font-medium text-gray-900 dark:text-white">{localLanguage.age_verification_options_message}</label>

              <select id="ageVerification" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={this.handleSelectChange}
                      value={verificationtype}>
                <option value="id_number">SSN</option>
                <option value="document">Document</option>
              </select>
              <div className = "mb-6" style={{ marginTop: '30px' }}>
                <button type="submit"   
                        style={{ color: '#ffffff', border: '2px solid #ffffff' }}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{localLanguage.age_verification_button_text}</button>
              </div>
              { displayFailedMessage && (
                              <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert" style={{ color: "red" }}>
                              <span className="font-medium">{localLanguage.age_verification_failed_message}</span>
                            </div>
              )}
              <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                 {localLanguage.age_verification_note_message}
              </div>
          </form>

        </div>
      </div>
    </div>
    );
  } if (showModal && showCountDown) {
    return (
    <div className="modal-overlay">
      <div className="modal-content" style={gradientStyle}>
      {isCountdownRunning ? (
      <div className="countdown">
            <h1>
              {localLanguage.age_verification_processing_message}
              <br />
            </h1>
            <div className="timer-wrapper" style={{ marginLeft: 80 }}>
              <CountdownCircleTimer
                className="countdownTimmer"
                isPlaying
                duration={6}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[10, 6, 3, 0]}
                onComplete={this.handleCountdownComplete}
              >
                {this.renderTime}
              </CountdownCircleTimer>
            </div>
            <p className="info">
              {localLanguage.age_verification_note_message}
            </p>
            
          </div>
      ) : (
        <div>
        <button
        style={{ color: "white" }}
        type="button"
        className="close-button"
        onClick={this.handleCloseModal}
        >X</button>
        <h2>{localLanguage.age_verification_processing_ongoing_message}</h2>
        </div>
      )}
      </div>
    </div>
    );
    }
    return null;
  }
}

CheckoutAgeVerification.contextType = AppContext;

export default withRouter(CheckoutAgeVerification);