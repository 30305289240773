import React, { Component } from "react";
import { Link } from "react-router-dom";

import SplitThePotHowItWorks from './SplitThePotHowItWorks';
import PrizeOnlyDrawHowItWorks from './PrizeOnlyDrawHowItWorks';
import ChaseTheAceHowItWorks from './ChaseTheAceHowItWorks';
import CalendarRaffleHowItWorks from './CalendarRaffleHowItWorks';
import { AppContext } from "../utils/context";

class MainHowItWorks extends Component {
  render() {
    const { event } = this.props;
    const { localLanguage, settings } = this.context;

    return (
      <div className="main-how-it-works bg-secondary text-secondary-contrast py-6">
        {event.game.id === 1 && (<SplitThePotHowItWorks
          event={event}
          step1_logo={settings.step1_logo}
          step2_logo={settings.step2_logo}
          step3_logo={settings.step3_logo}
          step4_logo={settings.step4_logo} />)}
        {event.game.id === 2 && (<PrizeOnlyDrawHowItWorks
          event={event}
          step1_logo={settings.step1_logo}
          step2_logo={settings.step2_logo}
          step3_logo={settings.step3_logo}
          step4_logo={settings.step4_logo}
        />)}
        {event.game.id === 3 && (<ChaseTheAceHowItWorks
          event={event}
          step1_logo={settings.step1_logo}
          step2_logo={settings.step2_logo}
          step3_logo={settings.step3_logo}
          step4_logo={settings.step4_logo}
          step5_logo={settings.step5_logo}
          />
        )}
        {event.game.id === 4 && (<CalendarRaffleHowItWorks
          event={event}
          step1_logo={settings.step1_logo}
          step2_logo={settings.step2_logo}
          step3_logo={settings.step3_logo}
          step4_logo={settings.step4_logo}
        />)}
        {event.is_active && event.web_enabled && event.websales_active && +event.web_sales_paused !== 1 && +event.shopify_enabled !== 1 && (
          <div className="flex justify-center" style={settings.font_family_hero !== "" ? { fontFamily: settings.font_family_hero } : { fontFamily: settings.font_family_hero }}>
            <Link
              to="/select-tickets"
              className="w-auto no-underline bg-accent hover:bg-accent-dark text-primary font-bold text-xl py-4 px-6 rounded mt-8 shadow-outline"
            >
              {localLanguage.buy_now_text}
            </Link>
          </div>
        )}
      </div>
    );
  }
}
MainHowItWorks.contextType = AppContext;

export default MainHowItWorks;
