import { apiUrl, appUrl } from "./constants";

export const axios = require("axios");
export const queryString = require('query-string');

export default class Request {
  init = () => {
    return axios.create({
      responseType: 'json',
      timeout: 15000,
    });
  };
  get = (endpoint, payload) => {
    let self = this;
    let instance = this.init();
    if (endpoint.indexOf('api:') > -1) {
        endpoint = endpoint.replace('api:', apiUrl());
    }
    if (endpoint.indexOf('app:') > -1) {
        endpoint = endpoint.replace('app:', appUrl);
    }

    var queryString = '';

    if (Object.keys(payload).length > 0) queryString = '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    return instance
      .get(`${endpoint}${queryString}`)
      .then(response => {
        const isResult = response.data && Object.keys(response.data).length;
        return isResult ? response.data : [];
      })
      .catch(error => {
        return self.handleError(error);
      })
      .then(response => {
        return response;
      });
  };
  post = (endpoint, payload) => {
    let self = this;
    let instance = this.init();
    instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    if (endpoint.indexOf('api:') > -1) {
        endpoint = endpoint.replace('api:', apiUrl());
    }
    return instance
      .post(endpoint, payload)
      .then(response => {
        const isResult = response.data && Object.keys(response.data).length;
        if (!isResult) throw new Error("No response data");
        return isResult ? response.data : [];
      })
      .catch(error => {
        return self.handleError(error);
      })
      .then(response => {
        return response;
      });
  };
  put = (endpoint, payload) => {
    let self = this;
    let instance = this.init();
    instance.defaults.headers.put["Content-Type"] = "application/x-www-form-urlencoded";
    if (endpoint.indexOf('api:') > -1) {
        endpoint = endpoint.replace('api:', apiUrl());
    }

    return instance
      .put(endpoint, payload)
      .then(response => {
        const isResult = response.data && Object.keys(response.data).length;
        if (!isResult) throw new Error("No response data");
        return isResult ? response.data : [];
      })
      .catch(error => {
        return self.handleError(error);
      })
      .then(response => {
        return response;
      });
  };
  download = (cls, endpoint, payload) => {
    let self = this;
    let instance = this.init();

    return instance
      .post(endpoint, payload)
      .then(response => {
        let blob = new Blob([response.data], {
            type: response.headers["content-type"]
          }),
        xlsUrl = window.URL.createObjectURL(blob),
        tempLink = document.createElement("a");
        tempLink.href = xlsUrl;
        // tempLink.setAttribute("download", "gameDetails.xlsx");
        setTimeout(() => tempLink.click(), 500);
        cls.setState({ isLoading: false });
      })
      .catch(error => {
        self.handleError(error); // shouldn't this be returned?
      });
  };
  handleError = error => {
    if (error.response) {
      // Adding check for 401 Unauthorized
      if (error.response.status === 401) {
        return error.response.status;
      }
      if (error.response.status === 403 && error.response.data.error == 'Unauthorized_Tenant_locked') {
          window.location.href = '/blocked';
      }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        exception: "The request was made but no response was received",
        message: error.request.statusText
          ? error.request.statusText
          : "Request error"
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        exception: "Error setting up the request",
        message: error.message ? error.message : "Request setup error"
      };
    }
  };
}

export const request = new Request();