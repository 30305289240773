import "./polyfills";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import cssVars from 'css-vars-ponyfill';
import ScrollToTop from  "../src/components/ScrollToTop";

// Default values
cssVars({
  // Targets
  rootElement   : document,
  shadowDOM     : false,

  // Sources
  include       : 'link[rel=stylesheet],style',
  exclude       : '',
  variables     : {},

  // Options
  onlyLegacy    : true,
  preserveStatic: true,
  preserveVars  : false,
  silent        : false,
  updateDOM     : true,
  updateURLs    : true,
  watch         : false,

  // Callbacks
  onBeforeSend(xhr, elm, url) {
    // ...
  },
  onWarning(message) {
    // ...
  },
  onError(message, elm, xhr, url) {
    // ...    
  },
  onSuccess(cssText, elm, url) {
    // ...
  },
  onComplete(cssText, styleElms, cssVariables, benchmark) {
    // ...
  }
});

const { detect } = require('detect-browser');
const browser = detect();


// handle the case where we don't detect the browser
switch (browser && browser.name) {
  // case 'ie':
  //   //BrowserNotSupported
  //   ReactDOM.render(
  //     <UnsupportedBrowser />,
  //     document.getElementById("root")
  //   );
  //   break;
 
  default:
    
    ReactDOM.render(
      <CookiesProvider>
        
        <Router basename={process.env.REACT_APP_BASENAME}>
        <ScrollToTop>
          <App />
          </ScrollToTop>
        </Router>
      </CookiesProvider>,
      document.getElementById("root")
    );

    break;
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
