import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faXTwitter,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";

import { AppContext } from "./../utils/context";

class SocialFollow extends Component {
    render() {

        const { socialFollowFacebookUrl, socialFollowTwitterkUrl, socialFollowYoutubeUrl, socialInstagramUrl, header_logo } = this.props;
        const { localLanguage } = this.context;

        return (
            <div className="social-container text-center">
                {header_logo && (
                    <img
                        src={header_logo}
                        alt={localLanguage.general_logo}
                        className="block mx-auto h-8 sm:h-16"
                    />
                )}
                {localLanguage.general_follow_us && (
                    <h3 className="py-2">{localLanguage.general_follow_us}</h3>
                )}
                {socialFollowYoutubeUrl && (
                    <a href={socialFollowYoutubeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="youtube social">
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                )}
                {socialFollowFacebookUrl && (
                    <a href={socialFollowFacebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="facebook social">
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                )}
                {socialFollowTwitterkUrl && (
                    <a href={socialFollowTwitterkUrl} className="twitter social" target="_blank"
                        rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} size="2x" />
                    </a>
                )}
                {socialInstagramUrl && (
                    <a href={socialInstagramUrl}
                        className="instagram social"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                )}
            </div>
        );
    }
}
SocialFollow.contextType = AppContext;

export default SocialFollow;
