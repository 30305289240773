import Validator from "validatorjs";
import address from 'email-addresses';


export function validateEmailAgainstDomains(email, domains) {

    let rules = {
        email: 'email'
    };
    let data = {
        email: email,
    };

    if (email == ''){
        return false;   
    }
    let validation = new Validator(data, rules);

    const ret = validation.passes();
    if (!ret) {
        return ret
    }

    const host = address.parseOneAddress(email);

    let jsonDomains = JSON.parse(domains);

    if (host.domain !== null && jsonDomains.domains.includes(host.domain)) {
        return true;
    }

    return false;
}
