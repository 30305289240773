import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from 'classnames';

import { AppContext } from "./../utils/context";
import { formatCurrency } from "../utils/constants";

class PrizeOnlyDraw extends Component {
  render() {
    const { title, date, draws, addons } = this.props.event;
    const { settings } = this.props;
    const { localLanguage, config } = this.context;

    const heroDrawTitleClasses = classnames('font-semibold', 'mb-2',  settings.font_size_raffle_title ); //text-3xl
    const heroProgressiveJackpotClasses = classnames('font-bold', settings.font_size_progressive_jackpot_title ); //text-5xl
    const heroDrawDateLabelClasses = classnames('font-light', settings.font_size_draw_date_label ); //text-xs
    const heroDrawDateClasses = classnames('font-light', settings.font_size_draw_date_value ); //text-base
    const heroAddonLabelClasses = classnames('heroAreaAddonLabel font-light', 'my-2', settings.font_size_prize_label ); //text-xl
    const heroAddonPrizeClasses = classnames('heroAreaAddonPrize font-bold ', 'text-xl' ); //text-xl

    return (
      <div>
        <div className={heroDrawTitleClasses}>{title}</div>
        <div className={heroDrawDateLabelClasses}>{localLanguage.general_draw_date}:</div>
        <div className={heroDrawDateClasses}>{date}</div>
        <div className="jackpot">
          <div className="text-xl my-3 text-primary">
            <div className={heroProgressiveJackpotClasses}>
              {draws[0]['title']}
            </div>
          </div>

          {config.option_allow_addons && +settings.hero_display_addon_prize > 0 && (addons?.length > 0 ?? false) && ( // List Add-on prizes
            <div className={heroAddonLabelClasses}>
                <span className="heroAddonPrizePrefix">
                    {`${localLanguage.general_hero_addon_raffle_prize.toUpperCase()}: `}
                </span>
                {addons.map((addon) => <div className={heroAddonPrizeClasses} key={addon.id} style={{ marginTop: '1em' }}>
                    <div style={{ fontSize:18, fontWeight:'normal' }}>{addon.title}</div>

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${addon.game_id === 1 ?
                                    localLanguage.general_jackpot : localLanguage.general_prize}:
                                ${addon.game_id === 1 ?
                                    formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_prize > 0 && +settings.hero_display_jackpot < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${localLanguage.general_prize}:
                                ${formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize > 0 &&
                        <>
                            <div>
                                {addon.game_id === 2 &&
                                    `${localLanguage.general_prize}: ${addon.prize}`}
                                {addon.game_id !== 2 &&
                                    `${addon.game_id === 1 ?
                                        localLanguage.general_jackpot : localLanguage.general_prize}:
                                    ${addon.game_id === 1 ?
                                        formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                                }
                            </div>

                            <div>{addon.game_id === 1 &&
                                `${localLanguage.general_prize}: ${formatCurrency(addon.prize)}`}
                            </div>
                        </>
                    }

                </div>)}
            </div>
        )}

          {draws[0].number !== null && (
            <div className="block py-3 px-6">
              <div className={draws[0].winning_card ? "text-primary" : ""}>
                <div className="text-base mb-2">{localLanguage.general_win_ticket}:</div>
                <div className="font-bold text-2xl mb-2">#{draws[0].number}</div>
                <Link
                  to={`/winners`}
                  className="inline-block font-bold rounded"
                >
                  {`${localLanguage.general_see_all_winners} >`}
                  </Link>
              </div>
            </div>
            )}
        </div>
      </div>
    );
  }
}
PrizeOnlyDraw.contextType = AppContext;

export default PrizeOnlyDraw;
