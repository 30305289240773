import React, { Component } from "react";
import Hero from "../components/MainHero";
import Tickets from "../components/MainTickets";
import LargestJackpot from "../components/MainLargestJackpot";
import MainHowItWorks from "../components/MainHowItWorks";
import EventSelection from "./EventSelection";
import { AppContext } from "../utils/context";
import RemainingCardsEnvelopes from "../components/RemainingCardsEnvelopes";
import Countdown from '../components/Countdown';
import { Helmet } from "react-helmet";
import SocialShare from '../components/SocialShare';

// use moment timezone library
const moment = require('moment-timezone');

class Home extends Component {
  render() {
    const { events, localLanguage, settings, event, config } = this.context;

    const timeZone = config.timeZone;

    // create a moment object for the event start and end time
    const startAt = moment.tz(event.start_at, timeZone);
    const endAt = moment.tz(event.end_at, timeZone);
    const startAtUTC = startAt.utc();
    const endAtUTC = endAt.utc();

    // handle sales countdown timer
    const now = moment.utc(); // UTC time
    const isBeforeSalesStart = now.isBefore(startAtUTC);
    const isAfterSalesEnd = now.isAfter(endAtUTC);

    let countdownLabel;

    if (isBeforeSalesStart) {
      countdownLabel = event.countdown_title || `${localLanguage.general_sales_start_in}:`;
    } else if (isAfterSalesEnd) {
      countdownLabel = localLanguage.general_sales_closed;
    } else {
      countdownLabel = `${localLanguage.general_sales_end_in}:`;
    }

    return (
      <div>
        <Helmet>
          {settings.scripts_home_page && <script>{settings.scripts_home_page}</script>}
        </Helmet>
        <SocialShare
          facebook={settings.facebook}
          twitter={settings.twitter}
          linkedIn={settings.linked_in}
          whatsApp={settings.whats_app}
          pinterest={settings.pinterest}
          reddit={settings.reddit}
          socialEmail={settings.social_email}
          title={settings.social_title ?  settings.social_title : settings.client_name.concat(" ", event.title ? event.title : "") }
          shareImage={settings.header_logo}
        />
        {event.id > 0 ? ( (isBeforeSalesStart && ! config.display_future_raffles_ecommerce) ?
         <div className="bg-white text-center py-4 lg:px-4 h-64">
            <p className="font-bold">{localLanguage.event_coming_soon_message}</p>
        </div> :
          <div>
            <Hero
              event={event}
            />
            <Tickets />
            {event.game.id === 3 && +settings.web_show_cards_remaining_home_page === 1 &&
              <RemainingCardsEnvelopes
                event={event}
              />
            }
            {+settings.web_countdown_enabled === 1 && <Countdown
              date={isBeforeSalesStart ? startAtUTC : endAtUTC}
              countdownLabel={countdownLabel}
            />
            }
            {+settings.display_largest_jackpot === 1 && (
              <LargestJackpot
                event={event}
              />
            )}
            <MainHowItWorks
              event={event}
            />
          </div>
          ) : <EventSelection
            events={events}
            cookies={this.props.cookies}
          />
        }
      </div>
    );
  }
}
Home.contextType = AppContext;

export default Home;
