import React, { Component } from "react";

import { AppContext } from "./../utils/context";
import { formatCurrency } from "../utils/constants";

class TicketSelectField extends Component { // Only to be used on Select-Tickets page
  render() {
    const {
      label,
      id,
      name,
      value,
      required,
      handleChange,
      hasError,
      disabled,
      hidden,
      eventName,
      gameType,
      gameId,
      isSTP,
      prizeLabel,
      prize,
      jackpotLabel,
      jackpot,
      addonPrizeLabel,
      addonPrize,
      addonJackpotLabel,
      addonJackpot,
      addonGameId,
      isFirstPricePoint,
      isAddonPP,
      isFirstEverAddonPP,
      mainEventTitle,
      displayAddonPPs
    } = this.props;

    const { localLanguage, settings } = this.context;

    let labelClass = "font-bold p-3";
    if (required) labelClass += " input-required";
    let containerClass = "w-full items-center"
    if (hidden) containerClass += " hideAddonPPs";
    if (displayAddonPPs) containerClass = "w-full items-center";

    const PrizeDisplay = ({ label, value, gameId }) => (
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {`${label}: ${gameId != 2 ? formatCurrency(value) : value}`}
        </span>
    );

    const Separator = () => <span style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px' }}> | </span>;


    return (
        <div className={containerClass}>
            {isFirstEverAddonPP && isAddonPP && <div className="mb-6 mt-6">
                <i className="flex border-accent">
                    {localLanguage.general_checkout_list_addon_raffle.replace("{mainEventTitle}", mainEventTitle)}
                </i>
            </div>}
            {isFirstPricePoint &&
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontSize: '22px', fontWeight: "bold" }}>
                        {eventName}
                    </span>
                    {+settings.hero_display_addon_prize > 0 &&
                        <span style={{ fontWeight: 300 }}> ({gameType}) </span>
                    }
                </div>
            }

            {/* Display the below only if hero_display_addon_prize setting is enabled */}

            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_jackpot > 0 && isSTP && isFirstPricePoint && !isAddonPP && (
            <PrizeDisplay label={jackpotLabel} value={jackpot} gameId={gameId} />
            )}
            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_jackpot > 0 && +settings.hero_display_prize > 0 && isSTP && isFirstPricePoint && !isAddonPP && (
            <Separator />
            )}
            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_prize > 0 && isFirstPricePoint && !isAddonPP && (
            <PrizeDisplay label={prizeLabel} value={prize} gameId={gameId} />
            )}
            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_jackpot > 0 && isSTP && isFirstPricePoint && isAddonPP && (
            <PrizeDisplay label={addonJackpotLabel} value={addonJackpot} gameId={addonGameId} />
            )}
            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_jackpot > 0 && +settings.hero_display_prize > 0 && isSTP && isFirstPricePoint && isAddonPP && (
            <Separator />
            )}
            {+settings.hero_display_addon_prize > 0 && +settings.hero_display_prize > 0 && isFirstPricePoint && isAddonPP && (
            <PrizeDisplay label={addonPrizeLabel} value={addonPrize} gameId={addonGameId}/>
            )}

            <div className="flex flex-wrap items-stretch items-center w-full mt-2 mb-2 relative">
                <div className="flex -mr-px">
                    <button
                    type="button"
                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-4 whitespace-no-wrap text-grey-dark font-semibold"
                    onClick={() => handleChange({ target: {id: id, value: value-1, text: "-" }})}
                    disabled={disabled}
                    >
                    {' - '}
                    </button>
                </div>
                <input
                    className="flex w-16 leading-normal border border-grey-light py-3 px-4 relative"
                    id={id}
                    name={name}
                    type="number"
                    placeholder={"0"}
                    value={value}
                    required={required}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <div className="flex -mr-px">
                    <button
                    type="button"
                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-4 whitespace-no-wrap text-grey-dark font-semibold"
                    onClick={() => handleChange({ target: {id: id, value: value+1, text: "+" }})}
                    disabled={disabled}
                    >
                    {' + '}
                    </button>
                </div>
                <div className="flex flex-2 items-center">
                    <label className={labelClass} htmlFor={id}>
                    {label}
                    </label>
                </div>
            </div>

            {hasError && <p className="text-danger text-xs italic">{hasError}</p>}
        </div>
    );
  }
}
TicketSelectField.contextType = AppContext;

export default TicketSelectField;
