import React, { Component } from "react";

class TextArea extends Component {
  render() {
    const {
      container,
      label,
      id,
      type,
      placeholder,
      name,
      value,
      required,
      handleChange,
      hasError
    } = this.props;
    let labelClass = "block font-bold mb-2";
    if (required) labelClass += " input-required";
    return (
      <div className={container}>
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
        <textarea
          className="appearance-none block w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id={id}
          name={name}
          type={type ? type : "text"}
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={handleChange}
         />
        {hasError && <p className="text-danger text-xs italic">{hasError}</p>}
      </div>
    );
  }
}

export default TextArea;
