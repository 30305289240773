import React, { Component } from "react";

import { AppContext } from "./../utils/context";
class PaymentTypes extends Component {

  render() {

    const { payment_mastercard,
      payment_mastercard_debit,
      payment_discover,
      payment_amex,
      payment_visa,
      payment_visa_debit,
      payment_interac
    } = this.props;

    const { localLanguage } = this.context;

    return (
      <div className="container mx-auto py-2">
        {localLanguage.general_payment_methods && (
          <h3 className="font-bold text-center text-center">
            {localLanguage.general_payment_methods}
          </h3>
        )}


        <div className="flex items-stretch flex-wrap justify-center">
          {payment_mastercard && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_mastercard}
                alt={localLanguage.general_mastercard}
              />

            </div>
          )}

          {payment_discover && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_discover}
                alt={localLanguage.general_discover}
              />
            </div>
          )}

          {payment_amex && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_amex}
                alt={localLanguage.general_american_express}
              />
            </div>
          )}

          {payment_visa && /*note pt-2 for visa logo to scale it better, really should just adjust image?*/(
            <div className=" md text-center m-2 pt-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_visa}
                alt={localLanguage.general_visa}
              />
            </div>
          )}
          {payment_visa_debit && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_visa_debit}
                alt={localLanguage.general_visa_debit}
              />
            </div>
          )}
          {payment_mastercard_debit && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_mastercard_debit}
                alt={localLanguage.general_visa_debit}
              />
            </div>
          )}
          {payment_interac && (
            <div className=" md text-center m-2">
              <img
                className="block w-16 h-auto mx-auto my-4"
                src={payment_interac}
                alt={localLanguage.general_interac}
              />
            </div>
          )}
        </div>
      </div>

    );
  }
}
PaymentTypes.contextType = AppContext;

export default PaymentTypes;
