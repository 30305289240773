import React, { Component } from "react";
import Select from 'react-select'

class SelectBeneficiary extends Component {


    render() {
        const {
            container,
            label,
            id,
            options,
            required,
            hasError,
            handleBeneficiary,
            placeholder,
            beneficiaryId
        } = this.props;
        let labelClass = "block font-bold mb-2";
        if (required) labelClass += " input-required";
        return (
            <div className={container}>
                <label className={labelClass} htmlFor={id}>
                    {label} {required ? <span className="text-danger">*</span> : ""}
                </label>
                <div className="relative text-black" >
                    <Select
                        menuPlacement = "top"
                        isClearable={true}
                        isSearchable={true}
                        options={options}
                        placeholder={placeholder}
                        value={options.find(obj => obj.value === beneficiaryId)}
                        onChange={handleBeneficiary}
                    />
                </div>
                {hasError && <p className="text-danger text-xs italic">{hasError}</p>}
            </div>
        );
    }
}

export default SelectBeneficiary;
