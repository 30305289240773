import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    RedditShareButton,
    EmailShareButton,

    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    PinterestIcon,
    RedditIcon,
    EmailIcon
} from "react-share";

import {
    faXTwitter
} from "@fortawesome/free-brands-svg-icons";

import { AppContext } from "../utils/context";

class SocialShare extends Component {


    render() {

        const { facebook, twitter, linkedIn, whatsApp, pinterest, reddit, socialEmail } = this.props


        const {
            url = String(window.location),
            title,
            shareImage,
            size = "2.5rem",
            twitterXShareSize = "2rem",
            twitterXfontSize = "1.5rem",
            twiiterXMarginBottom = "3px"
        } = this.props;

        return (
            <div className="iconbar">
                {+facebook === 1 && (
                    <FacebookShareButton
                        url={url}
                        quote={title}
                        className="sharebutton"
                    >
                        <FacebookIcon
                            size={size}
                        />
                    </FacebookShareButton>
                )}

                {+twitter === 1 && (
                    <TwitterShareButton
                        url={url}
                        quote={title}
                        className="sharebutton"
                        style={{ backgroundColor: "white", fontSize: twitterXShareSize, marginBottom: twiiterXMarginBottom}}
                    >
                        <FontAwesomeIcon icon={faXTwitter} style={{fontSize: twitterXfontSize, marginBottom: twiiterXMarginBottom}} />
                    </TwitterShareButton>
                )}
                {+whatsApp === 1 && (
                    <WhatsappShareButton
                        url={url}
                        title={title}
                        separator=":: "
                        className="sharebutton"
                    >
                        <WhatsappIcon size={size} />
                    </WhatsappShareButton>
                )}
                {+linkedIn === 1 && (
                    <LinkedinShareButton
                        url={url}
                        title={title}
                        windowWidth={750}
                        windowHeight={600}
                        className="sharebutton"
                    >
                        <LinkedinIcon
                            size={size}

                        />
                    </LinkedinShareButton>
                )}

                {+pinterest === 1 && (
                    <PinterestShareButton
                        url={String(window.location)}
                        media={`${shareImage}`}
                        windowWidth={1000}
                        windowHeight={730}
                        className="sharebutton"
                    >
                        <PinterestIcon size={size} />
                    </PinterestShareButton>
                )}

                {+socialEmail === 1 && (
                    <EmailShareButton
                        url={url}
                        subject={title}
                        body=""
                        className="sharebutton"
                    >
                        <EmailIcon size={size} />
                    </EmailShareButton>
                )}

                {+reddit === 1 && (
                    <RedditShareButton
                        url={url}
                        title={title}
                        windowWidth={660}
                        windowHeight={460}
                        className="sharebutton"
                    >
                        <RedditIcon size={size} />
                    </RedditShareButton>
                )}

            </div>
        )

    }
}

SocialShare.contextType = AppContext;

export default SocialShare;
