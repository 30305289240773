import React, { Component } from "react";

import { AppContext } from "../utils/context";
import { request } from "../utils/request";

class ContentManaged extends Component {
  state = {
    content: ''
  }
  componentDidMount = () => {
    this.getContent();
  }
  componentDidUpdate = prevProps => {
    if (this.props.page !== prevProps.page) {
      this.getContent();
    }
    if(window.location.hash){
      window.scrollTo(0, document.getElementById(''+window.location.hash.replace("#", "")+'').offsetTop);
    }
  }
  getContent = async () => {
    let page = await request.get(`api:/web/pages`, { page: `web${this.props.page}text` });
    this.setState({ content: page.data })
  }
  render() {
    let { page, title } = this.props;
    const { content } = this.state;
    return (
      <div className={`container mx-auto py-6 ${page}Page`}>
        <h1 className="mb-6">{title}</h1>
        <div className={`content`}
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </div>
    );
  }
}
ContentManaged.contextType = AppContext;

export default ContentManaged;
