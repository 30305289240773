import React, { Component } from "react";

class SelectField extends Component {
  render() {
    const {
      container,
      label,
      id,
      placeholder,
      name,
      value,
      options,
      required,
      handleChange,
      hasError,
      fontColor,
      noBorder,
      noDefaultOption,
      displayError,
      language
    } = this.props;

    let labelClass = "block font-bold mb-2";
    if (required) labelClass += " input-required";

    let selectClassNames = "appearance-none block w-full border rounded py-3 px-4 mb-3 leading-tight bg-white focus:outline-none focus:bg-white";
    if (fontColor) selectClassNames += ` ${fontColor}`;
    if (noBorder) selectClassNames = selectClassNames.replace("mb-3", "mb-2");

    let selectStyles = {};
    if (noBorder) selectStyles = {"border": "none", "backgroundColor": "transparent"};

    let downCaretClassNames = "pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker";
    if (noBorder) downCaretClassNames = "pointer-events-none absolute pin-y pin-r flex items-center text-grey-darker";

    return (
      <div className={container}>
        <label className={labelClass} htmlFor={id}>
          {label} {required ? <span className="text-danger">*</span> : ""}
        </label>
        <div className="relative">
          <select
            className={selectClassNames}
            id={id}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            required={required}
            onChange={handleChange}
            style={selectStyles}
          >
            {!noDefaultOption && <option value="" />}
            {options.map((v, k) => (
              <option key={v.key} value={v.value}>
                {v.text}
              </option>
            ))}
          </select>
          <div className={downCaretClassNames}>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        {hasError && language.includes('en') && <p className="text-danger text-xs italic">{hasError}</p>}
        {hasError && !language.includes('en') && <p className="text-danger text-xs italic">{displayError}</p>}
      </div>
    );
  }
}

export default SelectField;
