import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../utils/context";
import CheckoutError from "./CheckoutError";

class CheckoutThankYou extends Component {
  render() {
    const { order, cart, cartTotal, event, personal, settings, giftingOption, loading, error, errorReturnHome} = this.props;
    const { localLanguage } = this.context;

    let gtagEcommerce;
    if (settings.helmet_google_analytics_id) {
      gtagEcommerce = {
        send_to: settings.helmet_google_analytics_id,
        transaction_id: order.id,
        value: cartTotal,
        currency: settings.currency,
        items: []
      };
      cart && cart.forEach(cartItem => {
        if (cartItem.qty > 0) {
          gtagEcommerce.items.push({
            name: localLanguage.general_num_for.replace('{cartItem.tickets}', cartItem.tickets).replace('{cartItem.cost}', cartItem.cost),
            quantity: cartItem.qty,
            price: cartItem.cost
          });
        }
      });
    }

    let gtagConversion;
    if (settings.helmet_google_gtag_conversion) {
      gtagConversion = JSON.parse(settings.helmet_google_gtag_conversion);
      if (gtagConversion && gtagConversion.json) {
        gtagConversion.json.value = cartTotal
        gtagConversion.json.transaction_id = order.id
        gtagConversion.json.currency = settings.currency
      }
    }

    if (!order){ return (localLanguage.general_processing); }
    if (loading){
        return (<div className="flex text-2xl text-primary">
            <div className="bump-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>);
    }
    if (error.code > 0 || error.message !== "") {
        return  <CheckoutError error={error} order={order} event={event} errorReturnHome={errorReturnHome} />;
    }

    if (order['error']) {
      return (
          <div>
              <h2 className="mb-6">{order['error']['title']}</h2>
              <p className="leading-normal mb-6">{order['error']?.message?.replace("{$event->title}", event.title) ?? localLanguage.general_problem_processing}</p>
          </div>
      )
    } else if (order.orderstatus === 0 || order.orderstatus === 2) { // Order in progress || Order processed and tickets being generated
      return (<form className="w-full md:w-2/3 orderInProgressClass">
        <h2 className="mb-6">{localLanguage.general_thank_you}</h2>
        <p className="leading-normal mb-6">{localLanguage.general_order_placed}</p>

        <p className="leading-normal mb-6">
            {localLanguage.general_email_confirm_info.split('{personal.email}')[0]}
            <span className="font-bold text-accent">{` ${personal.email} `}</span>
            {localLanguage.general_email_confirm_info.split('{personal.email}')[1]}
        </p>

        <p className="leading-normal mb-6">{localLanguage.general_thank_you_nonProfit.split('{event.nonprofit.name}')[0]}
            <br />
            {event.nonprofit.name}
        </p>
        <p className="leading-normal">
            <Link to="/" className=" thankYouPageLink no-underline text-accent ">
                {localLanguage.general_back_to_homepage}
            </Link>
        </p>
      </form>);
    } else if (order.orderstatus === 1) { // Sale completed
      return (
        <div>
          <Helmet>
            {settings.helmet_google_gtag_conversion && gtagConversion && <script>{`gtag('` + gtagConversion.event + `', '` + gtagConversion.conversion + `',` + JSON.stringify(gtagConversion.json) + `);`}</script>}
            {settings.helmet_google_analytics_id && gtagEcommerce && <script>{`gtag('event', 'purchase',` + JSON.stringify(gtagEcommerce) + `);`}</script>}
            {settings.helmet_fbq_purchase_property_key && <script>{`fbq('track', '` + settings.helmet_fbq_purchase_property_key + `', {currency: "` + settings.currency + `", value: ` + cartTotal + `});`}</script>}
            {settings.scripts_sales_confirmation && <script>{settings.scripts_sales_confirmation}</script>}
          </Helmet>
          <form className="w-full md:w-2/3">
            <h2 className="mb-6">{localLanguage.general_thank_you}</h2>
            <p className="leading-normal mb-6">
                {localLanguage.general_purchase_thankyou.split('{event.title}')[0]}
                <span className="font-bold text-accent">
                    {event.title}
                </span>{localLanguage.general_purchase_thankyou.split('{event.title}')[1].split('{order.id}')[0]}
                <span className="font-bold text-accent">
                    {order.id}
                </span>{localLanguage.general_purchase_thankyou.split('{event.title}')[1].split('{order.id}')[1]}
            </p>
            <p className="leading-normal">{localLanguage.general_recipient_email_confirm}{" "}
              {+giftingOption > 0 && personal.recipientList.length > 0 &&
                <span>
                  {personal.recipientList.map((key, value) =>
                    personal.recipientList[value]['recipientListFirstName'] !== "" &&
                    personal.recipientList[value]['recipientListEmail'] + ", "
                  )}{localLanguage.general_and_to_recipient}{" "}
                </span>
              }{localLanguage.general_customer_email_confirm.split('{personal.email}')[0]}
                <span className="font-bold text-accent">
                {personal.email}
                </span>{localLanguage.general_customer_email_confirm.split('{personal.email}')[1]}
            </p>
            <Link to={`/print-tickets?orderId=${order.id}&hash=${order.hash}`}
              className="inline-block no-underline bg-accent hover:bg-accent-dark text-accent-contrast font-bold py-3 px-6 rounded my-4"
              target="_blank">{localLanguage.general_view_your_tickets}
            </Link>
            <p className="leading-normal mb-6">{localLanguage.general_questions_contact_us}</p>
            <p className="leading-normal mb-6">{localLanguage.general_thank_you_nonProfit.split('{event.nonprofit.name}')[0]}
            <br />
            {event.nonprofit.name}
            </p>
            <p className="leading-normal">
                <Link to="/" className=" thankYouPageLink no-underline text-accent ">
                    {localLanguage.general_back_to_homepage}
                </Link>
            </p>
          </form>
        </div>
      );
    } else if (order.orderstatus === 7) { // Process error
      return (
        <div>
          <h2 className="mb-6">{localLanguage.general_process_payment_error}</h2>
          <p className="leading-normal mb-6">
            { order.error || localLanguage.general_cvv_error_info}
            <Link to="/" style={{ color: "inherit" }}>
              <span>{" "}{localLanguage.general_homepage}</span>
            </Link>
          </p>
        </div>
      );
    } else if (order.orderstatus === 8) { // Voided
      return (
        <div>
          <h2 className="mb-6">{localLanguage.general_ticket_voided}</h2>
          <p className="leading-normal mb-6">
            {localLanguage.general_if_error_contact}
            <br/>
            <br/>
            {localLanguage.general_system_event_info.replace(
                "{event.title}", event.title).replace(
                    "{event.id}", event.id).replace(
                        "{event.date}", event.date)
            }
            <br/>
            {localLanguage.general_order_conf_num.replace("{order.id}", order.id)}
          </p>
        </div>
      );
    } else if (order.orderstatus === 6) { // Not completed (sales ended, limit reached, etc)
      return (
        <div>
          <h2 className="mb-6">{localLanguage.general_order_incomplete}</h2>
          <p className="leading-normal mb-6">
            <span>{localLanguage.general_please_try_again}</span>
            <Link to="/" style={{ color: "inherit" }}>
              <span>{localLanguage.general_homepage}</span>
            </Link>
          </p>
          <p className="leading-normal mb-6">
            {localLanguage.general_issue_contact_support}
          </p>
        </div>
      );
    } else { // order has an unhandled status
      return (
        <div>
          <h2 className="mb-6">{localLanguage.general_order_unrecognized_status}</h2>
          <p className="leading-normal mb-6">
            {localLanguage.general_system_event_info.replace(
                "{event.title}", event.title).replace(
                    "{event.id}", event.id).replace(
                        "{event.date}", event.date)
            }
            <br/>{localLanguage.general_order_conf_num.replace("{order.id}", order.id)}
            <br/>{localLanguage.general_order_status_id.replace("{order.orderstatus}", order.orderstatus)}
          </p>
        </div>
      );
    }
  }
}
CheckoutThankYou.contextType = AppContext;

export default CheckoutThankYou;
