import React, { Component } from "react";
import { AppContext } from "../utils/context";

class CheckoutSteps extends Component {
  render() {
    const { steps } = this.props;
    const { localLanguage } = this.context;
    return (
      <div className="flex flex-wrap justify-center mb-6 checkOutSteps text-white">
        {steps.map((s, k) => {
          let activeClass = (s.active === true) ? " border-primary bg-accent checkoutStepShow" : " border-primary-lighter bg-accent-lighter checkoutStepsHide";
          return (
            <div key={`step${k}`} className={`w-full sm:flex-1 mr-2 text-center block border rounded py-2 px-4 ${activeClass}`}>
              <span className="block text-xs">{localLanguage.general_step_num.replace('{k}', k + 1)} : {s.title}</span>
            </div>
          );
        })}
      </div>
    );
  }
}
CheckoutSteps.contextType = AppContext;

export default CheckoutSteps;
