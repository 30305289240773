import React, { Component } from "react";
import { Helmet } from "react-helmet";

import TicketSelectField from "./TicketSelectField";
import SelectBeneficiary from "../components/SelectBeneficiary";
import CheckoutError from "../components/CheckoutError";
import Button from "./Button";
import { AppContext } from "../utils/context";
import { formatCurrency } from "../utils/constants";

class CheckoutTickets extends Component {
  constructor(props) {
    super(props);

    let beneficiaries = [];

    const { context, cart } = props;

    context.event.beneficiaries.forEach(beneficiary => {
      if (beneficiary.logo_url) {
        beneficiaries.push({
          label: <div><img style={{ position: 'relative', borderRadius: '50%', verticalAlign: 'middle', width: '35px', height: '35px' }} src={beneficiary.logo_url} />{beneficiary.name}</div>,
          value: beneficiary.id
        });
      }
      else {
        beneficiaries.push({
          label: beneficiary.name,
          value: beneficiary.id
        });
      }
    })

    this.state = {
      beneficiaries: beneficiaries,
      addonCount: Object.keys(context?.event?.addons)?.length ?? 0,
      addonEvents: context?.event?.addons ?? null,
      currentEvent: context?.event
    };

    if (+context.event.shopify_enabled === 1) {
      window.location.href = "/";
    }

    // Redirect user to home page if cart's undefined
    if (!cart && window.location.pathname != '/free-tickets/select-tickets') {
      window.location.href = "/";
    }
  }

  render() {
    const {
        context,
        cart,
        handleCart,
        handleBeneficiary,
        validateCart,
        validateCartTickets,
        valid,
        goToPrevious,
        tickets_error,
        freeTicket,
        displayAddonPPsProp,
        beneficiaryId
    } = this.props;
    const { localLanguage, config } = this.context;
    const { beneficiaries, addonCount, addonEvents, currentEvent } = this.state;

    const eventEndedAlert = {
        code: 3,
        message: localLanguage.general_event_end_return_home,
        action: "default"
    }
    const priceChangeAlert = {
        code: 4,
        message: localLanguage.general_pricing_changed_reload,
        action: "reload"
    }

    let productForString = config.option_number_model === "single_number_multi_chance" ? localLanguage.general_num_chances_for : localLanguage.general_num_tickets_for;

    if (!context.event.is_active || context.event.web_sales_paused) { // alert user that event ended
        return (<CheckoutError
            error={eventEndedAlert}
            event={context.event}
        />);
    }

    let eventAddonsCount = Object.keys(context?.event?.addons)?.length ?? 0;
    if (context.config.option_allow_addons && addonCount !== eventAddonsCount) { // alert user of addon event changes
        return (<CheckoutError
            error={priceChangeAlert}
            event={context.event}
        />);
    }

    let prevEventAddons = context?.event?.addons ?? null;
    if (context.config.option_allow_addons && prevEventAddons && addonCount) { // alert user of addon dynamic pricing changes
        const addonPricingChange = addonEvents.every((addon, key) => {
            let prevAddonPrices = context?.event?.addons[key]?.prices;
            if (prevAddonPrices) return (JSON.stringify(prevAddonPrices) !== JSON.stringify(addon.prices));
        });
        if (addonPricingChange)
            return (<CheckoutError
                error={priceChangeAlert}
                event={context.event}
            />);
    }

    let prevEvent = context?.event;
    if (prevEvent && currentEvent && JSON.stringify(prevEvent.prices) !== JSON.stringify(currentEvent.prices)) { // alert user of dynamic pricing changes
        return (<CheckoutError
            error={priceChangeAlert}
            event={context.event}
        />);
    }

    let displayAddonPPs = displayAddonPPsProp || !context.config.option_addons_require_main;

    return (
      <form className="w-full md:w-2/3">
        <Helmet>
          {context.settings.scripts_select_tickets && <script>{context.settings.scripts_select_tickets}</script>}
        </Helmet>
        <h2 className="mb-6">
            {freeTicket && freeTicket > 0 ?
            localLanguage.general_claim_free_ticket :
            localLanguage.general_select_tickets}
        </h2>
        {freeTicket > 0 && context.event.title !== "" && <h4>{localLanguage.general_event}: {context.event.title}</h4>}
        <div className="flex flex-wrap mb-6">
          {cart &&
            cart.map((c, k) => (
                <TicketSelectField
                    key={k}
                    id={`cart-${k}`}
                    label={`${productForString.replace('{c.tickets}', c.tickets).replace('${c.cost}', formatCurrency(c.cost))}`}
                    value={c.qty}
                    disabled={c.disabled}
                    eventName={c.eventName}
                    gameType={c.gameType}
                    gameId={c.gameId}
                    isSTP={c.isSTP}
                    prizeLabel={c.prizeLabel}
                    prize={c.prize}
                    jackpotLabel={c.jackpotLabel}
                    jackpot={c.jackpot}
                    addonPrizeLabel={c.addonPrizeLabel}
                    addonPrize={c.addonPrize}
                    addonJackpotLabel={c.addonJackpotLabel}
                    addonJackpot={c.addonJackpot}
                    addonGameId={c.addonGameId}
                    isFirstPricePoint={c.isFirstPricePoint}
                    isAddonPP={c.isAddonPP}
                    isFirstEverAddonPP={c.isFirstEverAddonPP}
                    hidden={c.hidden}
                    mainEventTitle={context.event.title}
                    displayAddonPPs={displayAddonPPs}
                    handleChange={handleCart}
                />
            ))}
          {cart && context.event.beneficiaryenabled === 1 && 
            (
              <SelectBeneficiary
                container="w-full pr-3 pl-3 mb-6 md:mb-0"
                id="beneficiaryId"
                name="context.event.beneficiary"
                options={beneficiaries}
                required={+context.settings.mandatory_beneficiary === 1}
                handleBeneficiary={handleBeneficiary}
                label={localLanguage.beneficiary_label}
                placeholder={localLanguage.beneficiary_placeholder}
                beneficiaryId={beneficiaryId}
              />
            )
          }
          {tickets_error && (
            <div className="w-full flex justify-center mt-6">
              <div className="bg-white border border-danger text-danger px-4 py-3 rounded relative" role="alert">
                {valid.cartTotal && (<i className="block md:inline font-bold">{valid.cartTotal}</i>)}
                {valid["beneficiaryId"] && !valid.cartTotal && (<i className="block md:inline font-bold">{valid["beneficiaryId"]}</i>)}
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex flex-wrap mb-6 justify-center justify-between">
          {!freeTicket &&
            <Button
              onClick={goToPrevious}
              color="secondary"
              textColor="primary"
              content={localLanguage.general_back}
            />
          }
          {context.event.game.id === 3 ?
            <Button color="accent" textColor="primary" onClick={validateCart} content={localLanguage.general_continue} continueButton="true"/>
            :
            <Button color="accent" textColor="primary" onClick={validateCartTickets} content={localLanguage.general_continue} continueButton="true"/>
          }
        </div>
      </form>
    );
  }
}
CheckoutTickets.contextType = AppContext;

export default CheckoutTickets;
