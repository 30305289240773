import axios from 'axios';

const loc = window.location;
export const appEnv = process.env.REACT_APP_ENVIRONMENT;

export const apiUrl = () => {
  if (window._env_ && window._env_.REACT_APP_API_BASE_URL){
    return window._env_.REACT_APP_API_BASE_URL;
  }

  return axios.get(window._env_.REACT_APP_CENTRAL_BASE_URL + 'e-retrieve')
  .then((response) => {
    window._env_={'REACT_APP_API_BASE_URL': 'https://' + response.data.domain + '/api'};
    return  window._env_.REACT_APP_API_BASE_URL;
  });
}

export const appUrl = process.env.REACT_APP_APP_URL;
export const webUrl = process.env.REACT_APP_WEB_URL;
export const TIMEZONE = process.env.REACT_APP_TIMEZONE;
export const dateTimeFormat = process.env.REACT_APP_DATETIMEFORMAT;
export const dateFormat = process.env.REACT_APP_DATE_FORMAT;
export const timeFormat = process.env.REACT_APP_TIME_FORMAT;
export const language = process.env.REACT_APP_LANGUAGE;
export const currency = process.env.REACT_APP_CURRENCY;

export const blankImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==";

export const COUNTRIES = (locale='en-US') => {
    if (locale.includes('fr')) { return [
            {
                key: "USA",
                value: "É.-U.",
                text: "États-Unis"
            },
            {
                key: "CAN",
                value: "CAN",
                text: "Canada"
            }
        ];
    } else if (locale.includes('es')) { return [
            {
                key: "USA",
                value: "EE.UU",
                text: "Estados Unidos de América"
            },
            {
                key: "CAN",
                value: "CAN",
                text: "Canadá"
            }
        ];
    } else return [
        {
            key: "USA",
            value: "USA",
            text: "United States of America"
        },
        {
            key: "CAN",
            value: "CAN",
            text: "Canada"
        }
    ];
}
export const STATES = (locale='en-US') => {
    if (locale.includes('fr')) { return [
        {
            key: "AL",
            value: "AL",
            text: "Alabama",
            country: "USA"
        },
        {
            key: "AK",
            value: "AK",
            text: "Alaska",
            country: "USA"
        },
        {
            key: "AZ",
            value: "AZ",
            text: "Arizona",
            country: "USA"
        },
        {
            key: "AR",
            value: "AR",
            text: "Arkansas",
            country: "USA"
        },
        {
            key: "CA",
            value: "CA",
            text: "Californie",
            country: "USA"
        },
        {
            key: "CO",
            value: "CO",
            text: "Colorado",
            country: "USA"
        },
        {
            key: "CT",
            value: "CT",
            text: "Connecticut",
            country: "USA"
        },
        {
            key: "DE",
            value: "DE",
            text: "Delaware",
            country: "USA"
        },
        {
            key: "FL",
            value: "FL",
            text: "Florida",
            country: "USA"
        },
        {
            key: "GA",
            value: "GA",
            text: "Georgia",
            country: "USA"
        },
        {
            key: "HI",
            value: "HI",
            text: "Hawaii",
            country: "USA"
        },
        {
            key: "ID",
            value: "ID",
            text: "Idaho",
            country: "USA"
        },
        {
            key: "IL",
            value: "IL",
            text: "Illinois",
            country: "USA"
        },
        {
            key: "IN",
            value: "IN",
            text: "Indiana",
            country: "USA"
        },
        {
            key: "IA",
            value: "IA",
            text: "Iowa",
            country: "USA"
        },
        {
            key: "KS",
            value: "KS",
            text: "Kansas",
            country: "USA"
        },
        {
            key: "KY",
            value: "KY",
            text: "Kentucky",
            country: "USA"
        },
        {
            key: "LA",
            value: "LA",
            text: "Louisiane",
            country: "USA"
        },
        {
            key: "ME",
            value: "ME",
            text: "Maine",
            country: "USA"
        },
        {
            key: "MD",
            value: "MD",
            text: "Maryland",
            country: "USA"
        },
        {
            key: "MA",
            value: "MA",
            text: "Massachusetts",
            country: "USA"
        },
        {
            key: "MI",
            value: "MI",
            text: "Michigan",
            country: "USA"
        },
        {
            key: "MN",
            value: "MN",
            text: "Minnesota",
            country: "USA"
        },
        {
            key: "MS",
            value: "MS",
            text: "Mississippi",
            country: "USA"
        },
        {
            key: "MO",
            value: "MO",
            text: "Missouri",
            country: "USA"
        },
        {
            key: "MT",
            value: "MT",
            text: "Montana",
            country: "USA"
        },
        {
            key: "NE",
            value: "NE",
            text: "Nebraska",
            country: "USA"
        },
        {
            key: "NV",
            value: "NV",
            text: "Nevada",
            country: "USA"
        },
        {
            key: "NH",
            value: "NH",
            text: "New Hampshire",
            country: "USA"
        },
        {
            key: "NJ",
            value: "NJ",
            text: "New Jersey",
            country: "USA"
        },
        {
            key: "NM",
            value: "NM",
            text: "New Mexico",
            country: "USA"
        },
        {
            key: "NY",
            value: "NY",
            text: "New York",
            country: "USA"
        },
        {
            key: "NC",
            value: "NC",
            text: "North Carolina",
            country: "USA"
        },
        {
            key: "ND",
            value: "ND",
            text: "North Dakota",
            country: "USA"
        },
        {
            key: "OH",
            value: "OH",
            text: "Ohio",
            country: "USA"
        },
        {
            key: "OK",
            value: "OK",
            text: "Oklahoma",
            country: "USA"
        },
        {
            key: "OR",
            value: "OR",
            text: "Oregon",
            country: "USA"
        },
        {
            key: "PA",
            value: "PA",
            text: "Pennsylvanie",
            country: "USA"
        },
        {
            key: "RI",
            value: "RI",
            text: "Rhode Island",
            country: "USA"
        },
        {
            key: "SC",
            value: "SC",
            text: "South Carolina",
            country: "USA"
        },
        {
            key: "SD",
            value: "SD",
            text: "South Dakota",
            country: "USA"
        },
        {
            key: "TN",
            value: "TN",
            text: "Tennessee",
            country: "USA"
        },
        {
            key: "TX",
            value: "TX",
            text: "Texas",
            country: "USA"
        },
        {
            key: "UT",
            value: "UT",
            text: "Utah",
            country: "USA"
        },
        {
            key: "VT",
            value: "VT",
            text: "Vermont",
            country: "USA"
        },
        {
            key: "VA",
            value: "VA",
            text: "Virginia",
            country: "USA"
        },
        {
            key: "WA",
            value: "WA",
            text: "Washington",
            country: "USA"
        },
        {
            key: "DC",
            value: "DC",
            text: "Washington, D.C.",
            country: "USA"
        },
        {
            key: "WV",
            value: "WV",
            text: "West Virginia",
            country: "USA"
        },
        {
            key: "WI",
            value: "WI",
            text: "Wisconsin",
            country: "USA"
        },
        {
            key: "WY",
            value: "WY",
            text: "Wyoming",
            country: "USA"
        }
    ]} else if (locale.includes('es')) { return [
        {
            key: "AL",
            value: "AL",
            text: "Alabama",
            country: "USA"
        },
        {
            key: "AK",
            value: "AK",
            text: "Alaska",
            country: "USA"
        },
        {
            key: "AZ",
            value: "AZ",
            text: "Arizona",
            country: "USA"
        },
        {
            key: "AR",
            value: "AR",
            text: "Arkansas",
            country: "USA"
        },
        {
            key: "CA",
            value: "CA",
            text: "California",
            country: "USA"
        },
        {
            key: "CO",
            value: "CO",
            text: "Colorado",
            country: "USA"
        },
        {
            key: "CT",
            value: "CT",
            text: "Connecticut",
            country: "USA"
        },
        {
            key: "DE",
            value: "DE",
            text: "Delaware",
            country: "USA"
        },
        {
            key: "FL",
            value: "FL",
            text: "Florida",
            country: "USA"
        },
        {
            key: "GA",
            value: "GA",
            text: "Georgia",
            country: "USA"
        },
        {
            key: "HI",
            value: "HI",
            text: "Hawái",
            country: "USA"
        },
        {
            key: "ID",
            value: "ID",
            text: "Idaho",
            country: "USA"
        },
        {
            key: "IL",
            value: "IL",
            text: "Illinois",
            country: "USA"
        },
        {
            key: "IN",
            value: "IN",
            text: "Indiana",
            country: "USA"
        },
        {
            key: "IA",
            value: "IA",
            text: "Iowa",
            country: "USA"
        },
        {
            key: "KS",
            value: "KS",
            text: "Kansas",
            country: "USA"
        },
        {
            key: "KY",
            value: "KY",
            text: "Kentucky",
            country: "USA"
        },
        {
            key: "LA",
            value: "LA",
            text: "Luisiana",
            country: "USA"
        },
        {
            key: "ME",
            value: "ME",
            text: "Maine",
            country: "USA"
        },
        {
            key: "MD",
            value: "MD",
            text: "Maryland",
            country: "USA"
        },
        {
            key: "MA",
            value: "MA",
            text: "Massachusetts",
            country: "USA"
        },
        {
            key: "MI",
            value: "MI",
            text: "Michigan",
            country: "USA"
        },
        {
            key: "MN",
            value: "MN",
            text: "Minnesota",
            country: "USA"
        },
        {
            key: "MS",
            value: "MS",
            text: "Misisipi",
            country: "USA"
        },
        {
            key: "MO",
            value: "MO",
            text: "Misuri",
            country: "USA"
        },
        {
            key: "MT",
            value: "MT",
            text: "Montana",
            country: "USA"
        },
        {
            key: "NE",
            value: "NE",
            text: "Nebraska",
            country: "USA"
        },
        {
            key: "NV",
            value: "NV",
            text: "Nevada",
            country: "USA"
        },
        {
            key: "NH",
            value: "NH",
            text: "Nuevo Hampshire",
            country: "USA"
        },
        {
            key: "NJ",
            value: "NJ",
            text: "Nueva Jersey",
            country: "USA"
        },
        {
            key: "NM",
            value: "NM",
            text: "Nuevo México",
            country: "USA"
        },
        {
            key: "NY",
            value: "NY",
            text: "Nueva York",
            country: "USA"
        },
        {
            key: "NC",
            value: "CN",
            text: "Carolina del Norte",
            country: "USA"
        },
        {
            key: "ND",
            value: "DN",
            text: "Dakota del Norte",
            country: "USA"
        },
        {
            key: "OH",
            value: "OH",
            text: "Ohio",
            country: "USA"
        },
        {
            key: "OK",
            value: "OK",
            text: "Oklahoma",
            country: "USA"
        },
        {
            key: "OR",
            value: "OR",
            text: "Oregon",
            country: "USA"
        },
        {
            key: "PA",
            value: "PA",
            text: "Pensilvania",
            country: "USA"
        },
        {
            key: "RI",
            value: "RI",
            text: "Rhode Island",
            country: "USA"
        },
        {
            key: "SC",
            value: "CS",
            text: "Carolina del Sur",
            country: "USA"
        },
        {
            key: "SD",
            value: "DS",
            text: "Dakota del Sur",
            country: "USA"
        },
        {
            key: "TN",
            value: "TN",
            text: "Tennessee",
            country: "USA"
        },
        {
            key: "TX",
            value: "TX",
            text: "Texas",
            country: "USA"
        },
        {
            key: "UT",
            value: "UT",
            text: "Utah",
            country: "USA"
        },
        {
            key: "VT",
            value: "VT",
            text: "Vermont",
            country: "USA"
        },
        {
            key: "VA",
            value: "VA",
            text: "Virginia",
            country: "USA"
        },
        {
            key: "WA",
            value: "WA",
            text: "Washington",
            country: "USA"
        },
        {
            key: "DC",
            value: "DC",
            text: "Washington, D.C.",
            country: "USA"
        },
        {
            key: "WV",
            value: "VO",
            text: "Virginia Occidental",
            country: "USA"
        },
        {
            key: "WI",
            value: "WI",
            text: "Wisconsin",
            country: "USA"
        },
        {
            key: "WY",
            value: "WY",
            text: "Wyoming",
            country: "USA"
        }
    ]} else return [
        {
            key: "AL",
            value: "AL",
            text: "Alabama",
            country: "USA"
        },
        {
            key: "AK",
            value: "AK",
            text: "Alaska",
            country: "USA"
        },
        {
            key: "AZ",
            value: "AZ",
            text: "Arizona",
            country: "USA"
        },
        {
            key: "AR",
            value: "AR",
            text: "Arkansas",
            country: "USA"
        },
        {
            key: "CA",
            value: "CA",
            text: "California",
            country: "USA"
        },
        {
            key: "CO",
            value: "CO",
            text: "Colorado",
            country: "USA"
        },
        {
            key: "CT",
            value: "CT",
            text: "Connecticut",
            country: "USA"
        },
        {
            key: "DE",
            value: "DE",
            text: "Delaware",
            country: "USA"
        },
        {
            key: "FL",
            value: "FL",
            text: "Florida",
            country: "USA"
        },
        {
            key: "GA",
            value: "GA",
            text: "Georgia",
            country: "USA"
        },
        {
            key: "HI",
            value: "HI",
            text: "Hawaii",
            country: "USA"
        },
        {
            key: "ID",
            value: "ID",
            text: "Idaho",
            country: "USA"
        },
        {
            key: "IL",
            value: "IL",
            text: "Illinois",
            country: "USA"
        },
        {
            key: "IN",
            value: "IN",
            text: "Indiana",
            country: "USA"
        },
        {
            key: "IA",
            value: "IA",
            text: "Iowa",
            country: "USA"
        },
        {
            key: "KS",
            value: "KS",
            text: "Kansas",
            country: "USA"
        },
        {
            key: "KY",
            value: "KY",
            text: "Kentucky",
            country: "USA"
        },
        {
            key: "LA",
            value: "LA",
            text: "Louisiana",
            country: "USA"
        },
        {
            key: "ME",
            value: "ME",
            text: "Maine",
            country: "USA"
        },
        {
            key: "MD",
            value: "MD",
            text: "Maryland",
            country: "USA"
        },
        {
            key: "MA",
            value: "MA",
            text: "Massachusetts",
            country: "USA"
        },
        {
            key: "MI",
            value: "MI",
            text: "Michigan",
            country: "USA"
        },
        {
            key: "MN",
            value: "MN",
            text: "Minnesota",
            country: "USA"
        },
        {
            key: "MS",
            value: "MS",
            text: "Mississippi",
            country: "USA"
        },
        {
            key: "MO",
            value: "MO",
            text: "Missouri",
            country: "USA"
        },
        {
            key: "MT",
            value: "MT",
            text: "Montana",
            country: "USA"
        },
        {
            key: "NE",
            value: "NE",
            text: "Nebraska",
            country: "USA"
        },
        {
            key: "NV",
            value: "NV",
            text: "Nevada",
            country: "USA"
        },
        {
            key: "NH",
            value: "NH",
            text: "New Hampshire",
            country: "USA"
        },
        {
            key: "NJ",
            value: "NJ",
            text: "New Jersey",
            country: "USA"
        },
        {
            key: "NM",
            value: "NM",
            text: "New Mexico",
            country: "USA"
        },
        {
            key: "NY",
            value: "NY",
            text: "New York",
            country: "USA"
        },
        {
            key: "NC",
            value: "NC",
            text: "North Carolina",
            country: "USA"
        },
        {
            key: "ND",
            value: "ND",
            text: "North Dakota",
            country: "USA"
        },
        {
            key: "OH",
            value: "OH",
            text: "Ohio",
            country: "USA"
        },
        {
            key: "OK",
            value: "OK",
            text: "Oklahoma",
            country: "USA"
        },
        {
            key: "OR",
            value: "OR",
            text: "Oregon",
            country: "USA"
        },
        {
            key: "PA",
            value: "PA",
            text: "Pennsylvania",
            country: "USA"
        },
        {
            key: "RI",
            value: "RI",
            text: "Rhode Island",
            country: "USA"
        },
        {
            key: "SC",
            value: "SC",
            text: "South Carolina",
            country: "USA"
        },
        {
            key: "SD",
            value: "SD",
            text: "South Dakota",
            country: "USA"
        },
        {
            key: "TN",
            value: "TN",
            text: "Tennessee",
            country: "USA"
        },
        {
            key: "TX",
            value: "TX",
            text: "Texas",
            country: "USA"
        },
        {
            key: "UT",
            value: "UT",
            text: "Utah",
            country: "USA"
        },
        {
            key: "VT",
            value: "VT",
            text: "Vermont",
            country: "USA"
        },
        {
            key: "VA",
            value: "VA",
            text: "Virginia",
            country: "USA"
        },
        {
            key: "WA",
            value: "WA",
            text: "Washington",
            country: "USA"
        },
        {
            key: "DC",
            value: "DC",
            text: "Washington, D.C.",
            country: "USA"
        },
        {
            key: "WV",
            value: "WV",
            text: "West Virginia",
            country: "USA"
        },
        {
            key: "WI",
            value: "WI",
            text: "Wisconsin",
            country: "USA"
        },
        {
            key: "WY",
            value: "WY",
            text: "Wyoming",
            country: "USA"
        }
    ]
};

export const PROVINCES = (locale='en-US') => {
    if (locale.includes('fr')) {
        return [
            {
                key: "AB",
                value: "AB",
                text: "Alberta",
                country: "CAN"
            },
            {
                key: "BC",
                value: "BC",
                text: "Colombie-Britannique",
                country: "CAN"
            },
            {
                key: "MB",
                value: "MB",
                text: "Manitoba",
                country: "CAN"
            },
            {
                key: "NB",
                value: "NB",
                text: "Nouveau-Brunswick",
                country: "CAN"
            },
            {
                key: "NL",
                value: "NL",
                text: "Terre-Neuve-et-Labrador",
                country: "CAN"
            },
            {
                key: "NS",
                value: "NS",
                text: "Nouvelle-Écosse",
                country: "CAN"
            },
            {
                key: "ON",
                value: "ON",
                text: "Ontario",
                country: "CAN"
            },
            {
                key: "PE",
                value: "PE",
                text: "Île-du-Prince-Édouard",
                country: "CAN"
            },
            {
                key: "QC",
                value: "QC",
                text: "Québec",
                country: "CAN"
            },
            {
                key: "SK",
                value: "SK",
                text: "Saskatchewan",
                country: "CAN"
            }
        ]
    } else if (locale.includes('es')) {
        return [
            {
                key: "AB",
                value: "AB",
                text: "Alberta",
                country: "CAN"
            },
            {
                key: "BC",
                value: "BC",
                text: "Columbia Británica",
                country: "CAN"
            },
            {
                key: "MB",
                value: "MB",
                text: "Manitoba",
                country: "CAN"
            },
            {
                key: "NB",
                value: "NB",
                text: "Nuevo Brunswick",
                country: "CAN"
            },
            {
                key: "NL",
                value: "NL",
                text: "Newfoundland y Labrador",
                country: "CAN"
            },
            {
                key: "NS",
                value: "NS",
                text: "Nueva Escocia",
                country: "CAN"
            },
            {
                key: "ON",
                value: "ON",
                text: "Ontario",
                country: "CAN"
            },
            {
                key: "PE",
                value: "PE",
                text: "Isla del Príncipe Eduardo",
                country: "CAN"
            },
            {
                key: "QC",
                value: "QC",
                text: "Québec",
                country: "CAN"
            },
            {
                key: "SK",
                value: "SK",
                text: "Saskatchewan",
                country: "CAN"
            }
        ]
    } else return [
        {
            key: "AB",
            value: "AB",
            text: "Alberta",
            country: "CAN"
        },
        {
            key: "BC",
            value: "BC",
            text: "British Columbia",
            country: "CAN"
        },
        {
            key: "MB",
            value: "MB",
            text: "Manitoba",
            country: "CAN"
        },
        {
            key: "NB",
            value: "NB",
            text: "New Brunswick",
            country: "CAN"
        },
        {
            key: "NL",
            value: "NL",
            text: "Newfoundland and Labrador",
            country: "CAN"
        },
        {
            key: "NS",
            value: "NS",
            text: "Nova Scotia",
            country: "CAN"
        },
        {
            key: "ON",
            value: "ON",
            text: "Ontario",
            country: "CAN"
        },
        {
            key: "PE",
            value: "PE",
            text: "Prince Edward Island",
            country: "CAN"
        },
        {
            key: "QC",
            value: "QC",
            text: "Quebec",
            country: "CAN"
        },
        {
            key: "SK",
            value: "SK",
            text: "Saskatchewan",
            country: "CAN"
        }
    ]
}

export function formatInt(str) {
  let result = parseInt(str);
  return result.toLocaleString('en-US');
}
export function formatFloat(str, decimals = 0) {
  let result = parseFloat(str);
  return result.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
}
export function formatCurrency(str, decimals = 0) {
  let result = Math.floor(parseFloat(str));
  if (isNaN(result)) return str;

  const acceptedLocales = ['en', 'fr', 'es']; // accepted Locales
  let navigatorLanguage = navigator?.language ?? "en-US"; // browser default
  if (!acceptedLocales.includes(navigatorLanguage.substring(0,2))) navigatorLanguage = "en-US"; // set default locale
  let browserLocale = localStorage.getItem('browserLocale') ? localStorage.getItem('browserLocale') : navigatorLanguage;

  // isInteger
  return result.toLocaleString(browserLocale, { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).replace('US', '');
}
export function formatPercent(str, decimals = 0) {
  let result = parseFloat(str);
  // isInteger
  return result.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: decimals, maximumFractionDigits: decimals });
}
