import React, { Component } from "react";
import { AppContext } from "../utils/context";
import classnames from 'classnames';
import TextField from "../components/TextField";
import Button from "../components/Button";
import { validateEmailAgainstDomains } from "../utils/customFunctions";

class GrantAccess extends Component {

  state = {
    email: "",
    errorMsg: "",
    validEmail: false
  };

  handleSubmit = e => {
    const { cookies } = this.props
    cookies.set("employee_authorized", true, { path: "/", maxAge: 3600 });
  };

  handleChange = e => {
    const { settings } = this.context;
    let { name, value } = e.target;
    let email = this.state;
    let validEmail = this.state;
    email = value;

    validEmail = validateEmailAgainstDomains(email,settings.employee_raffle_valid_domains);
    this.setState({ email, validEmail });
  }

  render() {
    const { settings, localLanguage } = this.context;
    const { email, validEmail } = this.state;
    const heroBoxClassnames = classnames('w-full', 'text-center', 'box-it')

    return (
      <div className="video-main video-bg" style={{ backgroundColor: settings.hero_background_color }}>
        <div className="video cover align-center align-middle">
          {settings.hero_image && settings.hero_image !== "" ? (
            <img
              src={`${settings.hero_image}`}
              alt=""
              className="opacity-75"
            />
          ) : (
            settings.hero_background_color && settings.hero_background_color !== "" ? (
              <div style={{ backgroundColor: settings.hero_background_color }} />
            ) : (
              <div style={{ backgroundColor: "#FFF !important" }} />
            )
          )}
        </div>
        <div className="content" >
          <div className="main-hero py-6 absolute w-full max-w-full heroTextColorClass" style={{ color: settings.hero_text_color, zIndex: 1 }}>
            <div className="flex items-stretch flex-wrap md:flex-row w-full">
              <div className="w-full md:w-1/3 primarylogo" primaryLeftLogoClass />

              <div className="w-full md:w-1/3">
                <div className="flex items-stretch flex-wrap heroTextColorClass" style={{ color: settings.hero_text_color }}>

                  <div className={heroBoxClassnames} style={settings.font_family_hero !== "" ? { fontFamily: settings.font_family_hero } : { fontFamily: settings.font_family_hero }} >

                    <div className="w-full  text-center ">
                      <div className="font-semibold text-xl">
                        <form className="w-full text-center">
                          {settings.employee_raffle_message}
                          <div className="flex flex-wrap mb-6 pt-8">
                            <TextField
                              container="w-full mb-6 md:mb-0"
                              id="email"
                              name="email"
                              value={email}
                              placeholder={localLanguage.general_email}
                              handleChange={this.handleChange}
                            />
                            {validEmail === true && (
                              <div className="w-full flex flex-wrap mb-6 justify-between">
                                <Button type="button" onClick={this.handleSubmit} color="secondary" textColor="primary" content={localLanguage.general_enter} />
                              </div>
                            )}

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/3 primarylogo primaryRightLogoClass" />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GrantAccess.contextType = AppContext;

export default GrantAccess;
