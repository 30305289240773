import React, { Component } from "react";

class DisplayCard extends Component {
  render() {
    const {
      envelopeNumberColor,
      envelopeBackgroundImage,
      envelopeBackgroundImageHeight,
      cardInfo,
      clickHandler,
      envelope,
      flag
    } = this.props;
    let classes = "";
    let styles = {};
    let revealedCardsStyle = {};
    const DiamondsCardSuitStyle = cardInfo.suit === "D" ? <span>&diams;</span> : "";
    const HeartsCardSuitStyle = cardInfo.suit === "H" ? <span>&hearts;</span> : "";
    const SpadesCardSuitStyle = cardInfo.suit === "S" ? <span>&spades;</span> : "";
    const ClubsCardSuitStyle = cardInfo.suit === "C" ? <span>&clubs;</span> : "";
    const RedJokerCardSuitStyle = cardInfo.suit === "RJ" ? <span>&#x1F0DF; {cardInfo.suit}</span> : "";
    const BlackJokerCardSuitStyle = cardInfo.suit === "BJ" ? <span>&#x1F0CF; {cardInfo.suit}</span> : "";
    const cardSuitRank = cardInfo.suit !== "RJ" && cardInfo.suit !== "BJ" ? cardInfo.rank : "";
    switch (flag) {
      case "remainingCards":
        classes = "m-1 font-semibold rounded w-16 bg-white border border-gray";
        revealedCardsStyle = {
          color: cardInfo.suit === "D" || cardInfo.suit === "H" || cardInfo.suit === "RJ" ? 'red' : {}
        };
        return (
          <div className={classes}>
            <div className="py-4 px-3" style={revealedCardsStyle}>
              {DiamondsCardSuitStyle}
              {HeartsCardSuitStyle}
              {SpadesCardSuitStyle}
              {ClubsCardSuitStyle}
              {RedJokerCardSuitStyle}
              {BlackJokerCardSuitStyle}
              {cardSuitRank}
            </div>
          </div>
        )
      case "remainingEnvelopes":
        classes = "m-2 font-semibold rounded w-16 envelopeNumberColorClass";
        if (cardInfo.drawn && cardInfo.suit !== "") {
          classes += " bg-white border border-gray";
        } else {
          if (!envelopeBackgroundImage) {
            classes +=
              envelope === cardInfo.envelope
                ? " bg-accent text-dark"
                : " bg-accent text-white"
          }
        }
        styles = {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          color: envelopeNumberColor ? envelopeNumberColor : '#FFF'
        };
        revealedCardsStyle = {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          position: 'relative',
          color: (cardInfo.suit === "D" || cardInfo.suit === "H" || cardInfo.suit === "RJ") ? 'red' : {}
        }
        return (
          <div
            className={classes}
            data-index={+cardInfo.envelope}
            style={{ height: +envelopeBackgroundImageHeight / 10 }}
          >
            {cardInfo.drawn && cardInfo.suit !== "" ? (
              <div
                style={revealedCardsStyle}
              >
                {DiamondsCardSuitStyle}
                {HeartsCardSuitStyle}
                {SpadesCardSuitStyle}
                {ClubsCardSuitStyle}
                {RedJokerCardSuitStyle}
                {BlackJokerCardSuitStyle}
                {cardSuitRank}
              </div>
            ) : (
                <div className="relative text-center" >
                  {!envelopeBackgroundImage ? (
                    <div className="p-4">{cardInfo.envelope}</div>
                  ) : (
                      <div>
                        <img src={envelopeBackgroundImage} alt="" />
                        <span className="absolute" style={styles}>{cardInfo.envelope}</span>
                      </div>
                    )}
                </div>
              )}
          </div>
        )
      default:
        classes = "m-2 font-semibold rounded w-16 focus:outline-none envelopeNumberColorClass ";
        let overlayClasses = 'Overlay';
        if (cardInfo.drawn && cardInfo.suit !== "") {
          classes += " cursor-not-allowed bg-white border border-gray";
        } else {
          if (!envelopeBackgroundImage) {
            classes += envelope === cardInfo.envelope ? ' bg-secondary text-accent-contrast' : ' bg-accent hover:bg-secondary text-primary-contrast';
          } else {
            overlayClasses += envelope === cardInfo.envelope ? ' SelectedCard' : ' ';
          }
        }
        styles = {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          color: envelopeNumberColor ? envelopeNumberColor : '#FFF',
        };
        revealedCardsStyle = {
          transform: 'translate(-50%, 0)',
          top: '0',
          left: '50%',
          position: 'relative',
          color: (cardInfo.suit === "D" || cardInfo.suit === "H" || cardInfo.suit === "RJ") ? 'red' : {}
        }
        return (
          <button
            id="envelope"
            type="button"
            className={classes}
            onClick={clickHandler}
            data-index={+cardInfo.envelope}
            disabled={cardInfo.drawn && cardInfo.suit !== ""}
            style={{ height: +envelopeBackgroundImageHeight / 10 }}>
            {cardInfo.drawn && cardInfo.suit !== "" ? (
              <div style={revealedCardsStyle}>
                {DiamondsCardSuitStyle}
                {HeartsCardSuitStyle}
                {SpadesCardSuitStyle}
                {ClubsCardSuitStyle}
                {RedJokerCardSuitStyle}
                {BlackJokerCardSuitStyle}
                {cardSuitRank}
              </div>
            ) : (
                <div className="relative text-center" >
                  {!envelopeBackgroundImage ? (
                    <div className="p-4">{cardInfo.envelope}</div>
                  ) : (
                      <div className="CardButton">
                        <img src={envelopeBackgroundImage} alt="" />
                        <div className="Cardimage" />
                        <span className='NumberCard' style={styles}>{cardInfo.envelope}</span>
                        <div className={overlayClasses} />
                      </div>
                    )}
                </div>
              )}
          </button>
        )
    }
  }
}

export default DisplayCard;
