import React, { Component } from "react";

import bumpBlackEn from "../assets/images/power-bump-black-en.png";
import bumpBlackFr from "../assets/images/power-bump-black-fr.png";
import bumpBlackEs from "../assets/images/power-bump-black-es.png";
import { AppContext } from "../utils/context";


class FooterPoweredBy extends Component {

  render() {
    const { localLanguage } = this.context;
    let logo;
    switch (localLanguage.setLocale) {
      case 'fr-CA':
        logo = bumpBlackFr;
        break;
      case 'es-US':
        logo = bumpBlackEs;
        break;
      default:
        logo = bumpBlackEn;
        break;
    }

    return (
      <div className="container mx-auto ">
        <div className="bg-primary p-4">
          <div className="text-center">
            <a  href="https://www.bumpcbn.com" target="_blank" rel="noopener noreferrer">
              <img
                className="block w-48 h-auto mx-auto "
                src={logo}
                alt={localLanguage.general_bump_cbn_div}
              />
            </a>
          </div>
        </div>
      </div>

    );
  }
}
FooterPoweredBy.contextType = AppContext;

export default FooterPoweredBy;
