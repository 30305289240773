import React, { Component } from "react";
import { Link } from "react-router-dom";
import { request } from "../utils/request";
import { AppContext } from "../utils/context";
import { formatCurrency, language } from "../utils/constants";
import RemainingCardsEnvelopes from "../components/RemainingCardsEnvelopes";
import AddonEventWinnerList from "../components/AddonEventWinnerList";
import Button from "../components/Button";
import SelectField from "../components/SelectField";
import TextField from "../components/TextField";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();


const EnvelopeDetails = (props) => {
    let card = props?.draw?.card ?? null;
    if (card) return (
        <div>
            <span className="inline md:hidden font-bold">
            {`${props.localLanguage.general_card}: `}
            </span>
            {card.suit === "D" ? (
            <span style={{ color: "red" }}>
                &diams;
            </span>
            ) : (
                ""
            )}
            {card.suit === "H" ? (
            <span style={{ color: "red" }}>
                &hearts;
            </span>
            ) : (
                ""
            )}
            {card.suit === "S" ? (
            <span>&spades;</span>
            ) : (
                ""
            )}
            {card.suit === "C" ? (
            <span>&clubs;</span>
            ) : (
                ""
            )}
            {card.suit === "RJ" ? (
            <span style={{ color: "red" }}>
                &#x1F0DF; {props.localLanguage.general_red_joker}
            </span>
            ) : (
                ""
            )}
            {card.suit === "BJ" ? (
            <span>&#x1F0CF; {props.localLanguage.general_black_joker}</span>
            ) : (
                ""
            )}
            {card.suit !== "RJ" && card.suit !== "BJ" &&
            ` ${props.localLanguage.general_suit_in_envelope.replace("{card.rank}", card.rank).replace("{envelopeNumber}", card.envelope)}`}
        </div>
    )
    return null;
}

class Winners extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      disabled: false, // if type is v2, submit is disabled until recaptcha complete
      recaptchaEnable: context.config.option_require_recaptcha,
      recaptchaRef: recaptchaRef,
      recaptchaKey: context.config.google_recaptcha_public_key,
      recaptchaType: context.config.option_recaptcha_type,
      winnerLookupEnabled: context.settings.winners_lookup_enabled,
      notFoundMessage: null,
      winnerMessage: null,
      winners: [], 
      allWinners: [],
      isEmptyState: false,
      ticketNumber: '',
      eventId: null,
      eventList: [],
      validationError: false,
    };

  }

  componentDidMount = () => {
    return request.get("api:/web/winners", {}).then(resp => {
      const { data: winners } = resp;
      this.setState({ winners, allWinners: winners });
  
      const eventLists = winners.reduce((lists, winner) => {
        const { game_id, threads, addon_events, id, title } = winner;
  
        if (game_id === 3 && threads.length > 1) {
          const threadEvents = threads
            .filter(thread => thread.draws.length > 0)
            .map(thread => ({
              key: thread.id,
              text: thread.title,
              value: thread.id
            }));
          return [...lists, ...threadEvents];
        }
  
        if (addon_events && addon_events.length > 0) {
          const addonEvents = addon_events.map((addonEvent) => ({
            key: `${addonEvent.id}_addon`,
            text: addonEvent.title,
            value: addonEvent.id
          }));
          return [...lists, { key: id, text: title, value: id }, ...addonEvents];
        }
  
        return [...lists, { key: id, text: title, value: id }];
      }, []);
      this.setState({ eventList: [{ key: 0, text: this.context.localLanguage.all_events, value: 0 }, ...eventLists] });    
    });
  };

  handleSubmit = () => {
    if (this.state.validationError != null) {
      this.setState({ notFoundMessage: null, winnerMessage: null });
      return;
    }

    if (this.state.ticketNumber == "") {
      this.setState({ validationError: this.context.localLanguage.ticket_number_invalid });
      return;
    }
    let payload = {
      'event_id' : this.state.eventId,
      'ticket_number' : this.state.ticketNumber,
    }

      if (this.state.recaptchaEnable == false) {
        payload.testing= true;
        this.fetchWinner(payload)
        return;
      }
      recaptchaRef.current.executeAsync().then(value => {
        payload.recaptcha = value;
        this.fetchWinner(payload);
        return;
      });
    }

    fetchWinner = (payload) => {
      return request.post("api:/winners-lookup", payload).then(resp => {
        if (resp.error) {
          this.setState({ winnerMessage: null, notFoundMessage:  this.context.localLanguage.winner_not_found_text });
        } 
        if (resp.winner) {
          if (Array.isArray(resp.winner) && resp.winner.length > 1) {
            let winnerMessage = this.context.localLanguage.winner_found_text + "<br>";            
            let additionalText = resp.winner.map(winner => {
              let prize = winner.prize; // Changed from resp.winner.prize to winner.prize
              let winningNumber = winner.number;
              return this.context.localLanguage.general_prizes+ " : "+prize+" --  " + this.context.localLanguage.general_win_num+" : "+winningNumber;
            }).join('<br>');
            this.setState({ winnerMessage: winnerMessage + " " + additionalText, notFoundMessage: null });
          } else if (Array.isArray(resp.winner) && resp.winner.length == 1) {
            resp.winner = resp.winner[0];
            let prize = resp.winner.prize;
            let winningNumber = resp.winner.number;
            let additionalText = this.context.localLanguage.general_prizes+ " : "+prize+" --  " + this.context.localLanguage.general_win_num+" : "+winningNumber;
            this.setState({ winnerMessage:  this.context.localLanguage.winner_found_text+" "+additionalText, notFoundMessage: null });
          } else {
            this.setState({ winnerMessage: null, notFoundMessage:  this.context.localLanguage.loser_message });
          }
        }
        if (this.state.recaptchaEnable == true) {
          recaptchaRef.current.reset();
        }
      }).catch(err => {
        return err;
      }); 
    }

    handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
    
      if (name === 'eventId') {
        if (value == 0) {
          this.setState({ 
            eventId: null,
            winners: this.state.allWinners, 
            winnerMessage: null, 
            notFoundMessage: null,
            ticketNumber: ""
          });
          return;
        }  
        this.handleEventIdChange(value);
      }
    
      if (name === 'ticketNumber') {
        this.handleTicketNumberChange(value);
      }
    }
    
    handleEventIdChange = (value) => {
      this.setState({ winnerMessage: null, notFoundMessage: null });
    
      const selectedEventWinners = this.state.allWinners.find(winner => 
        winner.id == value || 
        (winner.threads && winner.threads.some(thread => thread.id == value)) ||
        (winner.addon_events && winner.addon_events.some(addon_event => addon_event.id == value))
      );
    
      let selectedThread = this.findThreadInEvent(selectedEventWinners, value);
      if (!selectedThread) {
        selectedThread = this.findThreadInAddonEvents(selectedEventWinners, value);
      }
    
      let selectedEventWinnersCopy = null;
      if (!selectedThread && selectedEventWinners) {
        selectedEventWinnersCopy = { ...selectedEventWinners, addon_events: null };
      }
      this.setState({ winners: [selectedThread ?? selectedEventWinnersCopy] });
    }
    
    handleTicketNumberChange = (value) => {
      const isNumber = /^\d+$/.test(value);
      if (isNumber) {
        // If the value is a pure number, remove the error message
        this.setState({ validationError: null });
      } else {
        // If the value is not a pure number, display the error message
        this.setState({ validationError: this.context.localLanguage.ticket_number_invalid });
      }
    }
    
    findThreadInEvent = (selectedEventWinners, value) => {
      if (selectedEventWinners && selectedEventWinners.threads.length > 1) {
        const foundThread = selectedEventWinners.threads.find(thread => thread.id == value);
        if (foundThread) {
          return {
            ...foundThread,
            game: selectedEventWinners.game,
            threads: [foundThread]
          };
        }
      }
      return null;
    }
    
    findThreadInAddonEvents = (selectedEventWinners, value) => {
      if (selectedEventWinners && selectedEventWinners.addon_events != null) {
        const foundThread = selectedEventWinners.addon_events.find(event => event.id == value);
        if (foundThread) {
          return {
            ...foundThread,
            game: selectedEventWinners.game,
            threads: [foundThread]
          };
        }
      }
      return null;
    }

  render() {
    const { winners, recaptchaEnable, recaptchaKey, recaptchaType } = this.state;
    const { localLanguage, settings } = this.context;
    let columnStyle = settings.winners_name_settings === 'disabled' ? "w-full md:w-1/4 text-accent px-2" : "w-full md:w-1/5 text-accent px-2"
    let textStyle = settings.winners_name_settings === 'disabled' ? "w-full md:w-1/4 px-2" : "w-full md:w-1/5 px-2"

    return (
      <div className={`container mx-auto py-6 winnersPage`}>
        <h1 className="mb-6 text-accent">{localLanguage.general_winners}</h1>
        {this.state.winnerLookupEnabled == true && (
        <>
        <div className="flex flex-col md:flex-row gap-4 mb-6 items-center">
        <div className={this.state.eventId ? "w-full md:w-2/3 mb-4 md:mb-0 md:mr-4" : "w-full md:w-2/3"}>
            {this.state.eventId ? (
                <>
                    <h3>{localLanguage.winner_look_ticket_number_header_text}</h3>
                    <h5>{localLanguage.winner_look_ticket_number_text}</h5>
                </>
            ) : (
                <>
                    <h3>{localLanguage.winner_look_event_select_header_text}</h3>
                    <h5>{localLanguage.winner_look_event_select_text}</h5>
                </>
            )}
        </div>
          <SelectField
            container={this.state.eventId ? "w-full md:w-1/3 mb-4 md:mb-0 md:pr-3 md:mr-4" : "w-full md:w-1/3 mb-4 md:mb-0 md:pr-3"}
            name="eventId"
            id="eventId"
            label={localLanguage.event_select_text}
            value={this.state.eventId}
            options={this.state.eventList}
            noDefaultOption={true}
            handleChange={this.handleChange}
          />
          {this.state.eventId && (
            <>
              <div className="w-full md:w-1/3 mb-4 md:mb-0 md:pr-3 md:mr-4" style={{position: 'relative'}}>
                  <label className="block font-bold mb-2" htmlFor="ticketNumber">
                      {localLanguage.ticket_number_placeholder} <span className="text-danger">*</span> 
                  </label>
                  <input
                      className="appearance-none block w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="ticketNumber"
                      name="ticketNumber"
                      type="text"
                      value={this.state.ticketNumber}
                      required={true}
                      onChange={this.handleChange}
                  />
                  {this.state.validationError && <div style={{color: 'red', position: 'absolute', top: '90%', fontSize: '0.8em'}}>{this.state.validationError}</div>}              
              </div>
              <div className="md:w-1/6 flex items-center justify-end md:mt-0" style={{marginTop: '20px'}}>
                <Button
                  color="secondary" 
                  textColor="primary"
                  width="full"
                  onClick={this.handleSubmit}
                />
              </div>
            </>
          )}
        </div>
        {(recaptchaEnable) &&
          (<div className="mb-2 mt-2">
            <ReCAPTCHA
              ref={this.state.recaptchaRef} 
              sitekey={recaptchaKey}
              onChange={recaptchaType === 'v2' ? this.onChange : null}
              size={recaptchaType === 'v2' ? 'small' : 'invisible'}
            />
          </div>)
        }
        <div>
        {this.state.winnerMessage && (
          <p style={{color: 'green', fontSize: '20px', fontWeight: 'bold'}} dangerouslySetInnerHTML={{ __html: this.state.winnerMessage }} />
        )}          
        {this.state.notFoundMessage && (<p style={{color: 'red', fontSize: '20px', fontWeight: 'bold'}}>{this.state.notFoundMessage}</p>)} 
        </div>
         </>)}
        {winners && winners.map((v, k) => (
          <div key={`eventSelection${k}`}>
            <div className="block mx-auto my-4 overflow-hidden rounded-lg no-underline bg-accent">
                <div className="flex items-center justify-center px-6 py-4">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-1/3 text-center">
                        <span className="font-bold text-white text-xl">
                            {localLanguage[`general_game_${v.game.id}_title`]}
                        </span>
                    </div>
                    <div className="md:w-1/3 text-center">
                        {v.game_id === 3 && (
                            <RemainingCardsEnvelopes event={v.threads[0]} settings={settings} isWinnersPage={true} />
                        )}
                    </div>
                </div>

              <div className="hidden md:flex flex-wrap bg-secondary font-bold py-4">
                <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_date} </div>
                <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_event} </div>
                <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_results}</div>
                <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_win_num} </div>
                {settings.winners_name_settings != 'disabled' && (<div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_winner_name} </div>)}

              </div>
              {v.threads.map(t => {
                if (t.draws.length === 0 && !v.addon_events) return null; // check for null data
                if (t.draws.length === 0 && v.addon_events) {
                  return <AddonEventWinnerList key={`addon-row-1`} event = {v} thread={t} index= {null} showMainEventTitle = {true}/>
                }
                return t.draws.map((d, i) => {
                  return (
                    <div
                    key={`winner-row-${i}`}
                      className={
                        Number.isInteger(i / 2)
                          ? "flex flex-wrap bg-grey-lighter py-4"
                          : "flex flex-wrap bg-white py-4"
                      }
                    >
                      <div className={textStyle} style={{textAlign:"center"}}>
                        <span className="inline md:hidden font-bold">
                          {localLanguage.general_date}:{" "}
                        </span>
                        {d.draw_at}
                      </div>

                      <div className={textStyle} style={{textAlign:"center"}}>
                        <span className="inline md:hidden font-bold">
                          {localLanguage.general_event}:{" "}
                        </span>
                        {t.title}
                      </div>

                      <div className={textStyle} style={{textAlign:"center"}}>
                      {v.game.id === 1 && (
                          <div>
                            {+settings.show_jackpot_on_winners_page === 1 && !d.is_secondary && (
                              <span className="inline font-bold">
                                {localLanguage.general_jackpot}:{" "}
                              </span>
                            )}
                            {+settings.show_jackpot_on_winners_page === 1 && !d.is_secondary && formatCurrency(Math.floor(+d.jackpot))}
                          </div>
                        )}
                        {+settings.show_weekly_jackpot === 1 && (
                          <span className="inline font-bold">
                            {v.game_id === 2 ? `${localLanguage.general_prize}: ` :
                                d.is_secondary ? `${d.title}` :
                                    `${d.title}: `}
                          </span>
                        )}
                        {v.game_id === 2 ? d.title :
                            d.is_secondary ? '' :
                                (d.winning_card && !t.draws[1] ?
                                    formatCurrency(Math.floor(+d.jackpot)) :
                                    (+settings.show_weekly_jackpot === 1 ?
                                        formatCurrency(Math.floor(t.thread_is_final && d.is_grand ? +d.jackpot : +d.prize)) : ""
                                    )
                                )
                        }
                        {v.game.id === 3 && d.winning_card && !t.draws[1] && (
                          <div>
                            {+settings.show_weekly_jackpot === 1 && (
                              <span className="inline font-bold">
                                {localLanguage[`game_${v.game.id}_prize_title`]}:{" "}
                              </span>
                            )
                            }
                            {+settings.show_weekly_jackpot === 1 && formatCurrency(Math.floor(+d.prize))}
                          </div>
                        )}
                        {t.thread_is_final && d.winning_card ? (
                            <div className="text-xs italic mt-1">
                                <Link to='/rules'>
                                    {localLanguage.general_see_rules_final_draw.replace("{final_draw_rules}", localLanguage[`game_${v.game.id}_final_draw`])}
                                </Link>
                            </div>) :
                            ""}
                      </div>

                      <div className={textStyle} style={{textAlign:"center"}}>
                        <span className="inline md:hidden font-bold">
                          {localLanguage.general_win_num}:{" "}
                        </span>
                        {d.number}
                        {(!t.thread_is_final || !d.is_grand || (t.thread_is_final && d.is_grand)) && d.card && (
                          <EnvelopeDetails draw={d} localLanguage={localLanguage} />
                        )}
                      </div>
                      {settings.winners_name_settings != 'disabled' && 
                      (<div className={textStyle} style={{textAlign:"center"}}>
                        <span className="inline md:hidden font-bold">
                        {localLanguage.general_winner_name}:{" "}
                        </span>
                         {d.formatted_winners_name}
                      </div>)}
                      <AddonEventWinnerList key={`addon-row-1`} event = {v} settings={settings} thread={t} index= {i} showMainEventTitle = {false}/>
                    </div>
                  )
                })
              })}
              <div className="flex justify-around  bg-primary text-base">
                <div
                  className="flex items-center leading-normal bg-secondary border-secondary rounded m-4 whitespace-no-wrap text-white font-semibold"
                >
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
Winners.contextType = AppContext;
export default Winners;
