import React, { Component } from "react";

import { formatCurrency } from "../utils/constants";
import { AppContext } from "../utils/context";

class AddonEventWinnerList extends Component {
  render() {
    const {event, thread, index, showMainEventTitle} = this.props
    const { localLanguage, settings } = this.context;
    let columnStyle = settings.winners_name_settings === 'disabled' ? "w-full md:w-1/4 text-accent px-2" : "w-full md:w-1/5 text-accent px-2"
    let textStyle = settings.winners_name_settings === 'disabled' ? "w-full md:w-1/4 px-2" : "w-full md:w-1/5 px-2"

    return (
        <div
            className={
                Number.isInteger(index / 2)
                ? "w-full flex flex-wrap bg-grey-lighter"
                : "w-full flex flex-wrap bg-white"
            }
        >
            {showMainEventTitle &&
                <div className="w-full flex flex-wrap py-4">
                    <div className={textStyle} style={{textAlign:"center"}}></div>
                    <div className={textStyle} style={{textAlign:"center"}}>{event.title}</div>
                    <div className={textStyle} style={{textAlign:"center"}}></div>
                    <div className={textStyle} style={{textAlign:"center"}}></div>
                    {settings.winners_name_settings != 'disabled' && (<div className={textStyle} style={{textAlign:"center"}}></div>)}
                </div>
            }
            {event.addon_events && (index === thread.draws.length - 1 || showMainEventTitle) &&
                <div className="w-full hidden md:flex flex-wrap bg-secondary font-bold py-4 my-4 mx-8">
                    <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_date} </div>
                    <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_addon_event} </div>
                    <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_prize} </div>
                    <div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_win_num} </div>
                    {settings.winners_name_settings != 'disabled' && (<div className={columnStyle} style={{textAlign:"center"}}> {localLanguage.general_winner_name} </div>)}
                </div>
            }
            {event.addon_events && event.addon_events.map((addon, j) => {
                return  <div
                            key={`addon-event-row-${j}`}
                            className={'w-full md:flex flex-wrap bg-grey-lighter'}
                        >
                            {addon.draws.map((ad, key) => {
                                if(index !== thread.draws.length - 1 && !showMainEventTitle) return false;
                                if(ad.game_id === 4 && ad.is_grand === 1 && !showMainEventTitle) return false;
                                return <div key={`addon-winner-row-${key}`} className={"w-full md:flex flex-wrap py-2"}>
                                    <div className={textStyle} style={{textAlign:"center"}}>
                                        <span className="inline md:hidden font-bold">
                                            {`${localLanguage.general_date}: `}
                                        </span>
                                        {ad.draw_at}
                                    </div>
                                    <div className={textStyle} style={{textAlign:"center"}}>
                                        <span className="inline md:hidden font-bold">
                                            {`${localLanguage.general_addon_event}: `}
                                        </span>
                                        {addon.title}
                                    </div>
                                    <div className={textStyle} style={{textAlign:"center"}}>
                                        {addon.game.id === 1 && (
                                            <div>
                                                {+settings.show_jackpot_on_winners_page === 1 && (
                                                    <span className="inline font-bold">
                                                        {localLanguage.general_jackpot}:{" "}
                                                    </span>
                                                )}
                                                {+settings.show_jackpot_on_winners_page === 1 && formatCurrency(Math.floor(+ad.jackpot))}
                                            </div>
                                        )}
                                        {+settings.show_weekly_jackpot === 1 && (
                                            <span className="inline font-bold">
                                            {addon.game_id === 2 ? `${localLanguage.general_prize}: ` : `${ad.title}: `}
                                            </span>
                                        )}
                                        {addon.game_id === 2 ? ad.title : formatCurrency(Math.floor(+ad.prize))}
                                    </div>
                                    <div className={textStyle} style={{textAlign:"center"}}>
                                        <span className="inline md:hidden font-bold">
                                            {`${localLanguage.general_win_num}: `}
                                        </span>
                                        {ad.number}
                                    </div>
                                    {settings.winners_name_settings != 'disabled' &&
                                    (<div className={textStyle + ' pr-10'} style={{textAlign:"center"}}>
                                        <span className="inline md:hidden font-bold">
                                            {`${localLanguage.general_winner_name}: `}
                                        </span>
                                        {ad.formatted_winners_name}
                                    </div>)}
                                </div>
                            })}
                    </div>
                }
            )}
        </div>
      );
  }
}
AddonEventWinnerList.contextType = AppContext;

export default AddonEventWinnerList;
