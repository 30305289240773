import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "./Button";
import { AppContext } from "../utils/context";

class CheckoutProcessing extends Component {

  render() {
    const { title, paysafe_after_payclick, paysafe_status, initPaysafe, shift4Loading, paysafeLoading } = this.props;
    const { localLanguage, settings, config } = this.context;
    return (
      <form key={4} className="w-full checkoutProcessing">
        <Helmet>
          {settings.scripts_payment_info && <script>{settings.scripts_payment_info}</script>}
        </Helmet>
        {config.webPayments !== "shift4" && <h2 className="mb-6">{title}</h2>}
        <div className="flex flex-wrap items-center">
          <div className="m-auto" id="paymentInfoWrapper">

            {config.webPayments === 'paysafe' && paysafeLoading && ( // Paysafe checkout loader
              <div className="flex text-2xl text-primary">
                <div className="bump-loader" style={{ position: 'relative', marginRight: '20px', marginTop: '-15px', left: '0%' }}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                {paysafe_after_payclick ? localLanguage.general_processing_payment : localLanguage.general_payment_redirect}
              </div>
            )}

            {config.webPayments === 'stripe' && ( // Stripe checkout loader
                <div className="flex text-2xl text-primary">
                    <div className="bump-loader" style={{ position: 'relative', marginRight: '20px', marginTop: '-15px', left: '0%' }}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.location.href.includes('process-order') ? localLanguage.general_processing_payment : localLanguage.general_payment_redirect}
                </div>
            )}

            {config.webPayments === 'shift4' && ( // Shift4 checkout
                <>
                    <div id="i4goFrame"></div>

                    {shift4Loading ? <div className="flex text-2xl text-primary">
                        <div className="bump-loader" style={{ position: 'relative' }}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div> :
                      <>
                        <button
                          className="pay-button pay-hidden google-pay-button"
                          style={{backgroundColor: '#4285F4', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px', marginBottom: '10px'}}
                          type="button"
                        >
                        </button>

                        <button
                          className="pay-button pay-hidden apple-pay-button"
                          style={{backgroundColor: 'black', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px' }}
                          type="button"
                        >
                        </button>
                      </>
                    }
                </>
            )}

            {config.webPayments === 'paysafe' && !paysafe_status && !paysafeLoading && ( // Paysafe iframe closed by user
                <div>
                    <div className="flex text-2xl text-primary">{localLanguage.general_payment_cancelled}</div>
                    <div className="w-full flex flex-wrap mt-12 justify-between">
                        <Link
                            to={"/"}
                            className="no-underline py-3 px-6 bg-danger hover:bg-danger-dark text-primary-contrast"
                        >
                            {localLanguage.general_cancel_order}
                        </Link>
                        <Button onClick={() => initPaysafe()} color="primary" content={localLanguage.general_make_payment} />
                    </div>
                </div>)
            }

          </div>
        </div>
      </form>
    );
  }
}
CheckoutProcessing.contextType = AppContext;

export default CheckoutProcessing;
