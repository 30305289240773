import React, { Component } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../utils/context";
import SelectField from "./SelectField";

class MainNav extends Component {
  state = {
    navActive: ""
  }
  handleActiveNav = () => {
    const { navActive } = this.state;
    this.setState({ navActive: navActive !== "" ? "" : "nav-active " })
  }
  render() {
    const { localLanguage, settings } = this.context;
    const { navActive } = this.state;
    const { handleLanguageSwitch, locale } = this.props;
    const languageOptions = [
        {
          text: "English",
          value: "en-US",
          key: "en"
        },
        {
          text: "Français",
          value: "fr-CA",
          key: "fr"
        },
        {
          text: "Español",
          value: "es-US",
          key: "es"
        }
    ];

    return (
      <div className="container mx-auto" style={{ maxWidth: "100%" }}>
        <nav className="flex items-center justify-between flex-wrap p-2 m-auto border-accent-lightest border-b-4" >
          <div className="w-2/3 sm:w-auto md:w-auto lg:w-auto xl:w-auto flex items-center text-primary-lightest mr-6 m-3">
            <Link to="/" className="headerLogoAnchor">
              <img
                src={settings.header_logo}
                alt={localLanguage.general_logo}
                className="block mx-auto h-8 sm:h-10 headerLogoClass"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button className="flex items-center px-3 py-2 border rounded text-accent-contrast border-accent-light hover:text-accent-dark hover:bg-accent-lightest" onClick={this.handleActiveNav}>
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>{localLanguage.general_menu}</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          {settings.webtitle_long !== "" && (
            <div className="w-full mt-4 block flex-grow lg:flex lg:items-center lg:w-auto lg:mt-0">
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                <span className="font-semibold text-xl  tracking-tight" >
                  {settings.webtitle_long}
                </span>
              </Link>
            </div>
          )}
          <div className={`${navActive}nav-items w-full block lg:flex lg:items-center lg:w-auto`}>
            <div className="text-sm lg:flex-grow ">
              <Link
                to="/winners"
                className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 winnersHeaderLink no-underline "
              >
                {localLanguage.general_winners}
              </Link>
              {settings && settings.weblocationtroubleshootingtext && settings.weblocationtroubleshootingtext !== "0" && (
                <Link
                  to="/location-troubleshooting"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-4 webaboutustextHeaderLink no-underline"
                >
                  {localLanguage.locationtroubleshooting_link_name}
                </Link>
              )}
              {settings && settings.webhowitworkstext && settings.webhowitworkstext !== "0" && (
                <Link
                  to="/how-it-works"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-4 webaboutustextHeaderLink no-underline"
                >
                  {localLanguage.howitworks_link_name}
                </Link>
              )}
              {settings && settings.webaboutustext && settings.webaboutustext !== "0" && (
                <Link
                  to="/about"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 webaboutustextHeaderLink no-underline "
                >
                  {localLanguage.aboutus_link_name}
                </Link>
              )}
              {settings && settings.webclaimformstext && settings.webclaimformstext !== "0" && (
                <Link
                  to="/claim-forms"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 webclaimformstextHeaderLink no-underline "
                >
                  {localLanguage.claimforms_link_name}
                </Link>
              )}
              {settings && settings.webfaqtext && settings.webfaqtext !== "0" && (
                <Link
                  to="/faq"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 webfaqtextHeaderLink no-underline "
                >
                  {localLanguage.faq_link_name}
                </Link>
              )}
              {settings && settings.webrulestext && settings.webrulestext !== "0" && (
                <Link
                  to="/rules"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 webrulestextHeaderLink no-underline "
                >
                  {localLanguage.rules_link_name}
                </Link>
              )}
              {settings && settings.webproceedstext && settings.webproceedstext !== "0" && (
                <Link
                  to="/proceeds"
                  className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 proceedstextHeaderLink no-underline "
                >
                  {localLanguage.proceeds_link_name}
                </Link>
              )}
              <Link
                to="/contact"
                className="block mt-4 lg:inline-block lg:mt-0 text-accent hover:text-accent-dark mr-6 contactHeaderLink no-underline "
              >
                {localLanguage.contactus_link_name}
              </Link>

            </div>
          </div>

          <div className="w-full block lg:flex lg:items-center lg:w-auto languageSwitchWrapper">
            <div className="text-sm lg:flex-grow ">
                <SelectField
                    fontColor="text-locale-picker"
                    id="languageSwitch"
                    name="languageSwitch"
                    value={languageOptions.find(obj => obj.key === locale.substring(0,2)).value}
                    options={languageOptions}
                    placeholder={locale}
                    noBorder={true}
                    noDefaultOption={true}
                    handleChange={handleLanguageSwitch}
                />
            </div>
          </div>
        </nav>

      </div>
    );
  }
}
MainNav.contextType = AppContext;

export default MainNav;
