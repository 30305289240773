import React, { Component } from "react";

import { AppContext } from "../utils/context";

class Button extends Component {
  render() {
    const { localLanguage } = this.context;
    const {
      id,
      onClick,
      bold,
      padded,
      rounded,
      color,
      content,
      textColor,
      continueButton
    } = this.props;
    let tColor = textColor;
    if(tColor === "")
    {
      tColor = color;
    }
    let classes = [];

    classes.push(bold ? "font-bold" : "");
    classes.push(padded ? "py-4 px-8" : "py-3 px-6");
    classes.push(rounded ? "rounded" : "");
    classes.push("shadow");

    classes.push(
      color ? `bg-${color} hover:bg-${color}-dark text-${tColor}-contrast ` : "bg-grey hover:bg-grey-dark text-black"
    );

    classes.push(
      continueButton ? `continue-button` : ``
    )

    return (
      <button id={id ? id : "buttonComponent"} type="button" className={classes.join(" ")} onClick={onClick}>
        {content ? content : localLanguage.general_submit}
      </button>
    );
  }
}
Button.contextType = AppContext;

export default Button;
